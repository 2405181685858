import axios from "axios";
import Cookies from "universal-cookie";

export const Request = axios.create();

Request.defaults.baseURL = process.env.REACT_APP_BASE_URL;

Request.defaults.timeout = 200000;

Request.interceptors.request.use(
  async (config: any) => {
    const cookies = new Cookies();
    const token = cookies.get("meta-token") as any;
    if (token !== null && token !== "null" && token !== undefined && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Accept = "application/json";
    config.withCredentials = false;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Request.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error?.response);
  }
);

