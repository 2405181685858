export const TwitterWhite: React.FC = () => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.539 19.5001C16.596 19.5001 21.5505 11.9956 21.5505 5.49906C21.5505 5.28906 21.5505 5.07606 21.5415 4.86606C22.5061 4.16779 23.3386 3.30329 24 2.31306C23.099 2.71034 22.1441 2.97217 21.1665 3.09006C22.1963 2.47452 22.9676 1.50602 23.337 0.364563C22.3695 0.937749 21.3105 1.34012 20.2065 1.55406C19.4643 0.763599 18.4821 0.23994 17.4121 0.0641994C16.3421 -0.111541 15.2441 0.0704453 14.288 0.581969C13.3319 1.09349 12.5712 1.90601 12.1237 2.89365C11.6761 3.8813 11.5668 4.98896 11.8125 6.04506C9.85461 5.94689 7.93922 5.43826 6.19056 4.55218C4.4419 3.66609 2.89903 2.42233 1.662 0.901563C1.03401 1.98619 0.842361 3.26916 1.12597 4.48996C1.40958 5.71076 2.14718 6.77786 3.189 7.47456C2.40831 7.44804 1.64478 7.2384 0.96 6.86256V6.93006C0.961346 8.06628 1.35496 9.16719 2.07431 10.0467C2.79366 10.9262 3.79462 11.5304 4.908 11.7571C4.48539 11.8735 4.04884 11.9315 3.6105 11.9296C3.30148 11.9305 2.99307 11.9019 2.6895 11.8441C3.00418 12.8221 3.61691 13.6773 4.44187 14.2898C5.26683 14.9022 6.2627 15.2413 7.29 15.2596C5.54483 16.6303 3.3891 17.3737 1.17 17.3701C0.778981 17.3717 0.388235 17.3492 0 17.3026C2.25227 18.7385 4.86795 19.5009 7.539 19.5001Z"
        fill="#000"
      />
    </svg>
  );
};
