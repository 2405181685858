import { FC, ReactNode } from "react";
import { CloseIcon } from "utils/iconPack";

interface Props {
  show: boolean;
  onClose: () => void;
  children?: ReactNode;
  title?: string;
}

const SidebarContainer: FC<Props> = ({ show, onClose, children, title }) => {
  return (
    <>
      <div
        className={`z-[1500] fixed 
        top-0 right-0 left-0 bottom-0 
        backdrop-blur ${show ? "block" : "hidden"}`}
        onClick={onClose}
      />
      <div
        className={`bg-[#05050D] fixed top-0 bottom-0
        slider-width max-w-full z-[1550] duration-500 text-white
        pt-8 overflow-hidden ${show ? "right-0" : "closed-slider"} `}
      >
        <div className="shadow -z-10" />
        <div className="flex justify-between items-center px-8">
          <span className="font-saira font-semibold text-2xl">{title}</span>
          <button onClick={onClose}>
            <CloseIcon />
          </button>
        </div>

        <div className="pt-8 h-[90%]">{children}</div>
      </div>
      <style>{`
        .shadow {
          position: absolute;
          width: 635.36px;
          height: 650.14px;
          left: -20px;
          top: 0px;

          background: radial-gradient(50% 50% at 50% 50%, rgba(241, 39, 17, 0.32) 0%, rgba(15, 12, 41, 0) 100%);
          filter: blur(150px);
          border-radius: 233.5px;
          transform: matrix(0.51, -0.86, 0.75, 0.66, 0, 0);
        }
        .slider-width {
          width : 360px;
        }
        .closed-slider {
          right : -360px;
        }
      `}</style>
    </>
  );
};

export default SidebarContainer;
