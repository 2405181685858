import { IPlaques } from "entities/plaque.entity";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { mintAndTransferNFT } from "blockchain/functions";
import { refetchLookupData } from "redux/reducers/appReducer";
import { blockchainErrorHandler } from "blockchain/helper";
import { setTransactionId } from "redux/reducers/cartReducer";

interface ApproveChangeProps {
  setStep: (n: number) => void;
  ping: string;
  privateKey: string;
}

const ApproveChange: React.FC<ApproveChangeProps> = ({
  setStep,
  ping,
  privateKey,
}) => {
  const dispatch = useAppDispatch();

  const { plaques } = useAppSelector((state) => state.cart.purchase);

  const [loading] = useState(true);

  const [error, setError] = useState("");

  function checkTypeOfTransfer(plaqueDetail: IPlaques[]) {
    setError("");
    dispatch(setTransactionId(""));
    const totalPrice = plaques.reduce(
      (pre, curr) => BigInt(pre) + BigInt(curr.price),
      BigInt(0)
    );

    mintAndTransferNFT(
      plaques.map((p) => p.name.toLocaleLowerCase()),
      plaques.map(() => 1),
      JSON.stringify({ username: ping }),
      totalPrice.toString(),
      () => {},
      privateKey
    )
      .then((res: any) => {
        dispatch(setTransactionId(res.transactionHash));
        setStep(4);
        dispatch(refetchLookupData(true));
      })
      .catch((e) => {
        const errorMessage = blockchainErrorHandler(e);
        dispatch(setTransactionId(""));
        dispatch(refetchLookupData(true));
        setError(errorMessage);
      });
  }

  useEffect(() => {
    if (error === "Balance is not enough") {
      setStep(2);
    }
  }, [error]);

  useEffect(() => {
    checkTypeOfTransfer(plaques);
  }, []);

  const onContinueClick = () => {
    checkTypeOfTransfer(plaques);
  };

  return (
    <div className="mt-[24px] pb-8">
      <div className="flex flex-col justify-center px-5 lg:px-8">
        <p className="text-2xl font-semibold font-saira mb-[40px] relative text-white">
          Approve Purchase ...
        </p>
        <p className="mb-[4px] text-white">
          You’ll be asked to review and approve this purchase from your wallet.
        </p>
        {error === "" ? (
          <div>{loading && <div className="circle-loader mx-auto mt-7" />}</div>
        ) : (
          <>
            <div className="mb-[24px] mt-[56px]">
              {error ? "You rejected the request in your wallet." : null}
            </div>
            <button
              className="w-full h-[48px] bg-colorful rounded-2xl"
              onClick={onContinueClick}
            >
              Continue
            </button>
          </>
        )}
      </div>
      <style>
        {`
        .circle-loader {
          border: 4px solid #f3f3f3;
          border-radius: 50%;
          border-top: 4px solid #F12711;
          width: 27px;
          height: 27px;
          -webkit-animation: spin 2s linear infinite; /* Safari */
          animation: spin 2s linear infinite;
        }
        
        /* Safari */
        @-webkit-keyframes spin {
          0% {
            -webkit-transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
          }
        }
        
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }        
        `}
      </style>
    </div>
  );
};

export { ApproveChange };
