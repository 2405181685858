import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPlaques } from "entities/plaque.entity";

interface ModalsTypes {
  connectWallet: boolean;
  cartSidebar: boolean;
  buyTpa: boolean;
  cartModal: {
    status: boolean;
    options: boolean;
  };
  socialShareModal: { show: boolean; type: "facebook" | "twitter" | "link" };
  getTPALink: {
    data: IPlaques | null;
    status: boolean;
  };
}

const initialState: ModalsTypes = {
  connectWallet: false,
  cartSidebar: false,
  buyTpa: false,
  cartModal: {
    status: false,
    options: false,
  },
  socialShareModal: { show: false, type: "link" },
  getTPALink: {
    data: null,
    status: false,
  },
};

export const modalsReducer = createSlice({
  name: "app",
  initialState,
  reducers: {
    connectWalletModalStatus: (state, action: PayloadAction<boolean>) => {
      state.connectWallet = action.payload;
    },
    buyTpaModalStatus: (state, action: PayloadAction<boolean>) => {
      state.buyTpa = action.payload;
    },
    cleanUpStates: (state) => {
      state.connectWallet = false;
      state.buyTpa = false;
    },
    cartModalOptions: (state, action: PayloadAction<boolean>) => {
      state.cartModal.options = action.payload;
    },
    cartModalStatus: (state, action: PayloadAction<boolean>) => {
      state.cartModal.status = action.payload;
      if (!action.payload) {
        state.cartModal.options = false;
      }
    },
    changeCartSidebarStatus: (state, action: PayloadAction<boolean>) => {
      state.cartSidebar = action.payload;
      if (!action.payload) {
        state.cartModal.options = false;
      }
    },
    setSocialShareModal: (
      state,
      action: PayloadAction<ModalsTypes["socialShareModal"]>
    ) => {
      state.socialShareModal.type = action.payload.type;
      state.socialShareModal.show = action.payload.show;
    },
    getTPALinkModalStatus: (state, action: PayloadAction<boolean>) => {
      state.getTPALink.status = action.payload;
    },
    getTPALinkModalData: (state, action: PayloadAction<IPlaques | null>) => {
      state.getTPALink.data = action.payload;
    },
  },
});

export const {
  buyTpaModalStatus,
  connectWalletModalStatus,
  cleanUpStates,
  cartModalOptions,
  cartModalStatus,
  changeCartSidebarStatus,
  setSocialShareModal,
  getTPALinkModalStatus,
  getTPALinkModalData,
} = modalsReducer.actions;

export default modalsReducer.reducer;
