import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserTypes {
  refreshLookupData: boolean;
  refreshCardsData: boolean;
  refreshWorldData: boolean;
  wrongNetwork: {
    network: string;
    status: boolean;
  };
  toaster: {
    show: boolean;
    message: string;
    type?: 'success' | 'error';
  };
}

const initialState: UserTypes = {
  refreshLookupData: false,
  refreshCardsData: false,
  refreshWorldData: false,
  wrongNetwork: {
    network: 'wrong network',
    status: false,
  },
  toaster: {
    show: false,
    message: '',
    type: 'success',
  },
};

export const appReducer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    refetchLookupData: (state, action: PayloadAction<boolean>) => {
      state.refreshLookupData = action.payload;
    },
    refetchCardsData: (state, action: PayloadAction<boolean>) => {
      state.refreshCardsData = action.payload;
    },
    refetchWorldData: (state, action: PayloadAction<boolean>) => {
      state.refreshWorldData = action.payload;
    },
    toast: (state, action: PayloadAction<UserTypes['toaster']>) => {
      state.toaster = action.payload;
    },
    changeNetworkStatus: (
      state,
      action: PayloadAction<{
        network: string;
        status: boolean;
      }>,
    ) => {
      state.wrongNetwork = action.payload;
    },
  },
});

export const {
  refetchLookupData,
  refetchWorldData,
  refetchCardsData,
  changeNetworkStatus,
  toast,
} = appReducer.actions;

export default appReducer.reducer;
