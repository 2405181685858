import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { setPingAccount } from "redux/reducers/cartReducer";
import { Request } from "api/request";
import { URLS } from "api/endpoints";
import { getCookie } from "cookies-next";
import { META_PLAQUE_PING } from "constants/cookie";
// import { setSuccessPurchases } from 'redux/reducers/cartReducer';
import Modal from "../container";
import { AccountForm } from "./AccountForm";
import { ApproveChange } from "./ApproveChange";
import { BillingInfo } from "./billingInfo";
import { NoFunds } from "./noFund";
import { ProcessingPurchase } from "./ProcessingPurchase";
import { SuccussResult } from "./SuccussResult";
import { PurchaseFailed } from "./purchaseFailed";
import { VerificationCode } from "./verificationCode";
import { SuccessPurchase } from "cart/functions";
import SocialShareModal from "./socialShare";
import { setSocialShareModal } from "redux/reducers/modalsReducer";

export interface BuyPlaqueModalProps {
  show: boolean;
  onClose: () => void;
}

const BuyPlaqueModal: React.FC<BuyPlaqueModalProps> = ({ onClose, show }) => {
  const dispatch = useAppDispatch();
  const { pingAccount, pingPlaque } = useAppSelector(
    (state) => state.cart.purchase
  );
  const { socialShareModal } = useAppSelector((state) => state.modals);
  const [step, setStep] = useState(0);
  const [ping, setPing] = useState(pingAccount);
  const [privateKey, setPrivateKey] = useState<string>("");
  const [initCode, setInitCode] = useState("");

  useEffect(() => {
    setStep(0);
    setPing(pingAccount);
    setInitCode("");
  }, [show]);

  async function checkEnergy(plaque: string) {
    const response = await Request.get(URLS.plaques.plaqueOwnerUsername, {
      params: {
        name: plaque,
      },
    });
    if (response) {
      setPingAccount(response.data.username);
      setInitCode(plaque);
    }
  }

  useEffect(() => {
    if (pingPlaque !== "" && step !== 4) {
      checkEnergy(pingPlaque);
    }
  }, [pingPlaque, show]);

  const content: { [x: number]: JSX.Element } = {
    0: (
      <Modal show={show} onClose={onClose} maxWidth={490} bgRightShadow>
        <AccountForm
          setPingAccount={(p) => setPing(p)}
          ping={ping}
          initCode={initCode}
          setInitCode={(p) => setInitCode(p)}
          setStep={(st) => setStep(st)}
        />
      </Modal>
    ),
    1: (
      <Modal show={show} onClose={onClose} maxWidth={500} bgRightShadow>
        <BillingInfo setStep={(st) => setStep(st)} />
      </Modal>
    ),
    2: (
      <Modal show={show} onClose={onClose} maxWidth={490} bgRightShadow>
        <NoFunds setStep={(st) => setStep(st)} />
      </Modal>
    ),
    3: (
      <Modal show={show} onClose={onClose} maxWidth={490} bgRightShadow>
        <ApproveChange
          ping={ping}
          setStep={(st) => setStep(st)}
          privateKey={privateKey}
        />
      </Modal>
    ),
    4: (
      <Modal show={show} onClose={onClose} maxWidth={490} bgRightShadow>
        <ProcessingPurchase setStep={(st) => setStep(st)} />
      </Modal>
    ),
    5: (
      <Modal show={show} onClose={onClose} maxWidth={980} bgRightShadow>
        <SuccussResult ping={ping} />
      </Modal>
    ),
    6: (
      <Modal show={show} onClose={onClose} maxWidth={490} bgRightShadow>
        <PurchaseFailed setStep={(st) => setStep(st)} />
      </Modal>
    ),
    7: (
      <Modal show={show} onClose={onClose} maxWidth={490} bgRightShadow>
        <VerificationCode
          setStep={(st) => setStep(st)}
          setPrivateKey={(p) => setPrivateKey(p)}
        />
      </Modal>
    ),
  };

  return content[step];
};

export default BuyPlaqueModal;
