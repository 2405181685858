import * as React from 'react';
import { SVGProps } from 'react';

const SVGComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_5822_96207)">
      <circle cx={16} cy={16} r={16} fill="white" />
      <path
        d="M13.76 18.88L10.28 15.4C9.98667 15.1067 9.62667 14.96 9.2 14.96C8.77333 14.96 8.4 15.12 8.08 15.44C7.78667 15.7333 7.64 16.1067 7.64 16.56C7.64 17.0133 7.78667 17.3867 8.08 17.68L12.64 22.24C12.9333 22.5333 13.3067 22.68 13.76 22.68C14.2133 22.68 14.5867 22.5333 14.88 22.24L23.96 13.16C24.2533 12.8667 24.4 12.5067 24.4 12.08C24.4 11.6533 24.24 11.28 23.92 10.96C23.6267 10.6667 23.2533 10.52 22.8 10.52C22.3467 10.52 21.9733 10.6667 21.68 10.96L13.76 18.88ZM16 32C13.7867 32 11.7067 31.5797 9.76 30.7392C7.81333 29.8997 6.12 28.76 4.68 27.32C3.24 25.88 2.10027 24.1867 1.2608 22.24C0.420267 20.2933 0 18.2133 0 16C0 13.7867 0.420267 11.7067 1.2608 9.76C2.10027 7.81333 3.24 6.12 4.68 4.68C6.12 3.24 7.81333 2.09973 9.76 1.2592C11.7067 0.419733 13.7867 0 16 0C18.2133 0 20.2933 0.419733 22.24 1.2592C24.1867 2.09973 25.88 3.24 27.32 4.68C28.76 6.12 29.8997 7.81333 30.7392 9.76C31.5797 11.7067 32 13.7867 32 16C32 18.2133 31.5797 20.2933 30.7392 22.24C29.8997 24.1867 28.76 25.88 27.32 27.32C25.88 28.76 24.1867 29.8997 22.24 30.7392C20.2933 31.5797 18.2133 32 16 32Z"
        fill="#4ADE80"
      />
    </g>
    <defs>
      <clipPath id="clip0_5822_96207">
        <rect width={32} height={32} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
