export const URLS = {
  auth: {
    profile: "/auth/profile",
    stageWalletRegister: `/auth/stage-wallet`,
    emailVerification: "/auth/email-verification",
    emailVerificationCode: "/auth/evaluate-email-verification-code",
    nonce: (address: string) => `auth/${address}/nonce`,
    signature: (address: string) => `auth/${address}/signature`,
  },
  plaques: {
    index: "/plaques",
    id: (id: number | string) => `/plaques/id/${id}`,
    featured: "/plaques/featured",
    owned: "/plaques/owned",
    userPlaques: "/users/fetch/nfts",
    sold: "/plaques/sold",
    lookUp: "plaques/lookup",
    buy: "plaques/buy",
    plaqueOwnerUsername: "/plaques/owner-username",
    prepare: (name: string) => `plaques/prepare/${name}`,
    prepareButton: (name: string) => `plaques/prepare/${name}`,
  },
  contracts: {
    latest: "/contracts/latest",
    contracts: "/contracts",
  },
  metamask: {
    plaquePrice: "/metamask/get-plaque-price",
    floorPrice: "/metamask/get-plaque-floor-price",
    stageWalletPrivateKey: "/metamask/stage-wallet-private-key",
    requestPrivateKey: "/metamask/request/private-key",
    privateKey: "/metamask/private-key",
  },
  eth: {
    statistics: "/eth/statistics/sold-plaques",
  },
};

