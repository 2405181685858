import * as React from 'react';
import { SVGProps } from 'react';

const SVGComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21 12.5C21 14.28 20.4722 16.0201 19.4832 17.5001C18.4943 18.9802 17.0887 20.1337 15.4442 20.8149C13.7996 21.4961 11.99 21.6743 10.2442 21.3271C8.49836 20.9798 6.89472 20.1226 5.63604 18.864C4.37737 17.6053 3.5202 16.0016 3.17294 14.2558C2.82567 12.51 3.0039 10.7004 3.68509 9.05585C4.36628 7.41131 5.51983 6.00571 6.99987 5.01677C8.47991 4.02784 10.22 3.5 12 3.5C14.52 3.5 16.93 4.5 18.74 6.24L21 8.5"
      stroke="black"
      stroke-width="2.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 3.5V8.5H16"
      stroke="black"
      stroke-width="2.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SVGComponent;
