import { useState } from "react";
import { useForm } from "react-hook-form";
import { InputInlineError } from "components/inline-error";
import { Request } from "api/request";
import { URLS } from "api/endpoints";
import { EmailRegex } from "constants/regex";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { setEmail, setTempToken } from "redux/reducers/userReducer";

interface CreateFormData {
  username: string;
  email: string;
}

interface CreateProps {
  setForm: (value: string) => void;
}

const Create: React.FC<CreateProps> = ({ setForm }: CreateProps) => {
  const dispatch = useAppDispatch();

  const tempToken = useAppSelector((state) => state.user.tempToken);

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<CreateFormData>();

  const emailError = errors.email?.message || errors.email?.type;

  const onSubmit = async (data: CreateFormData) => {
    setLoading(true);

    const token = tempToken as string;
    try {
      const updateProfile = await Request.post(
        URLS.auth.profile,
        {
          username: data.username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).catch((err: any) => {
        setError("username", {
          message: err?.data?.message,
          type: "required",
        });
      });
      if (updateProfile) {
        dispatch(setEmail(data.email));
        dispatch(setTempToken(updateProfile.data?.token));
        const emailVerify = await Request.get(URLS.auth.emailVerification, {
          params: {
            email: data.email,
          },
          headers: {
            Authorization: `Bearer ${updateProfile.data?.token}`,
          },
        }).catch((err: any) => {
          setError("email", {
            message: err?.data?.message,
            type: "required",
          });
        });
        if (emailVerify) {
          setForm("code");
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col flex-1 justify-center items-center py-[80px]">
        <span className="text-2xl font-normal text-center mt-[48px] mb-[32px]">
          Create Account
        </span>
        <form
          className="w-5/6 md:w-[384px] mb-7 text-black"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full mb-3">
            <input
              className="w-full h-[48px] p-4 border border-[#E5E5E5] rounded-md placeholder:text-[#737373]"
              placeholder={"Email"}
              {...register("email", {
                required: "Please enter your email",
                validate: {
                  please_enter_a_valid_field: (value) =>
                    EmailRegex.test(value?.trim().toLowerCase()),
                },
              })}
              disabled={loading}
              type="email"
            />
            <InputInlineError error={emailError} />
          </div>
          <div className="w-full mb-3">
            <input
              className="w-full h-[48px] p-4 border border-[#E5E5E5] rounded-md placeholder:text-[#737373]"
              placeholder={"Account Name"}
              {...register("username", {
                required: "Please enter your account name",
              })}
              disabled={loading}
            />
            <InputInlineError error={errors.username?.message} />
          </div>
          <button
            type="submit"
            disabled={loading}
            className="flex mt-14 bg-colorful items-center justify-center flex-row h-[48px] w-full md:w-[384px] rounded-2xl"
          >
            {loading ? (
              <span
                className={`w-full h-full flex items-center justify-center relative ${
                  loading ? "block" : "hidden"
                }`}
              >
                Loading...
              </span>
            ) : (
              <span className="text-white text-xl font-semibold ">
                Continue
              </span>
            )}
          </button>
        </form>
      </div>
      <style>
        {`
          .metamask-icon {
            width: 150px;
            height: 29.48px;
          }
          .confirm-btn {
            background: linear-gradient(
              107.15deg,
              #da39e0 0%,
              #6e6aff 53.65%,
              #320d6c 100%
            );
          }
        `}
      </style>
    </>
  );
};

export default Create;
