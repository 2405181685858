import { useState } from "react";
import { useForm } from "react-hook-form";
import { InputInlineError } from "components/inline-error";
import { Request } from "api/request";
import { URLS } from "api/endpoints";
import { useAppSelector } from "redux/hooks";
import { ProviderNames } from "constants/configs";
import { getRequestedProvider } from "blockchain/auth";
import { loginUser } from "utils/userAuth";

interface AccountFormData {
  username: string;
}

interface AccountProps {
  setForm: (value: string) => void;
  providerName: ProviderNames;
}

const Account: React.FC<AccountProps> = ({
  setForm,
  providerName,
}: AccountProps) => {
  const tempToken = useAppSelector((state) => state.user.tempToken);

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<AccountFormData>();

  const onSubmit = async (data: AccountFormData) => {
    setLoading(true);
    const token = tempToken as string;

    try {
      const updateProfile = await Request.post(
        URLS.auth.profile,
        {
          username: data.username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).catch((err: any) => {
        setError("username", {
          message: err?.data?.message,
          type: "required",
        });
      });
      if (updateProfile) {
        const provider = await getRequestedProvider(providerName);
        loginUser(
          updateProfile.data?.token,
          updateProfile.data?.user,
          providerName,
          provider
        );
        setForm("complete");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col flex-1 justify-center items-center pb-[32px]">
        <span className="text-xl font-normal text-center mt-[48px] mb-[32px] text-white">
          Create Account
        </span>
        <form
          className="w-5/6 md:w-[384px] mb-[28px] text-black"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full ">
            <input
              className="w-full h-[48px] p-4 border border-[#E5E5E5] rounded-md placeholder:text-[#737373]"
              placeholder={"Account Name"}
              {...register("username", { required: "Please enter your account name" })}
              disabled={loading}
            />
            <InputInlineError error={errors.username?.message} />
          </div>
          <button
            type="submit"
            disabled={loading}
            id="register-btn-id"
            className="flex mt-[32px] bg-colorful items-center justify-center flex-row h-[48px] w-full md:w-[384px] rounded-2xl register-btn"
          >
            {loading ? (
              <span
                className={`w-full h-full flex items-center justify-center relative ${
                  loading ? "block" : "hidden"
                }`}
              >
                Loading...
              </span>
            ) : (
              <span className="text-white text-xl font-semibold">
                Register
              </span>
            )}
          </button>
        </form>
      </div>
      <style>
        {`
          .metamask-icon {
            width: 150px;
            height: 29.48px;
          }
          .confirm-btn {
            background: linear-gradient(
              107.15deg,
              #da39e0 0%,
              #6e6aff 53.65%,
              #320d6c 100%
            );
          }
          .register-btn {
          }
        `}
      </style>
    </>
  );
};

export default Account;

