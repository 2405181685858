import { Success as SuccessIcon } from "assets/icons/Success";

interface CompleteProps {
  onExplore: any;
}

const Complete: React.FC<CompleteProps> = ({ onExplore }: CompleteProps) => {
  return (
    <>
      <div className="flex flex-col flex-1 justify-center items-center py-[64px]">
        <SuccessIcon />
        <p className="mb-[56px] mt-[32px] text-2xl font-semibold font-saira text-center md:whitespace-pre leading-7 text-white">
          Login Successful
        </p>
        <button
          onClick={() => onExplore()}
          className="flex bg-colorful items-center justify-center flex-row h-[48px]  w-5/6 md:w-[384px] rounded-2xl text-white"
        >
          <span className="text-white text-base font-semibold ">
            Explore
          </span>
        </button>
        <style>
          {`
          .done-icon {
            width: 92px;
            height: 92px; 
          }
          .confirm-btn {
            background: linear-gradient(
              107.15deg,
              #da39e0 0%,
              #6e6aff 53.65%,
              #320d6c 100%
            );
          }
        `}
        </style>
      </div>
    </>
  );
};

export default Complete;
