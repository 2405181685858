import { FC } from "react";
import { Control, Controller, ControllerProps } from "react-hook-form";
import { InputInlineError } from "components/inline-error";
import VerificationInput from "react-verification-input";

interface Props {
  control: Control<any, any>;
  label?: string;
  name: string;
  rules?: ControllerProps["rules"];
  className?: string;
  lenght?: number;
  placeholder?: string;
}

export const Verification: FC<Props> = ({
  control,
  label,
  name,
  rules,
  className,
  lenght = 5,
  placeholder,
}) => {
  return (
    <div className={`w-full flex flex-col items-center ${className}`}>
      {!!label && (
        <label htmlFor={name} className="text-xs font-medium">
          {label}{" "}
          {rules?.required && (
            <span className="text-red-500 ml-0.5 text-sm ">*</span>
          )}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <>
            <VerificationInput
              autoFocus
              placeholder={placeholder || ""}
              classNames={{
                container: "w-full h-fit flex justify-center",
                character: `w-[50px] h-[60px] md:mx-1 rounded-2xl border-2 bg-transparent flex items-center justify-center text-lg font-bold ${
                  error
                    ? "border-red-500 text-red-500"
                    : "border-white text-white "
                }`,
              }}
              length={lenght}
              validChars="0-9"
              {...field}
            />
            <InputInlineError error={error?.message} />
          </>
        )}
      />
    </div>
  );
};

