import HamburgerIcon from "assets/icons/HamburgerIcon";
import MyTPALogo from "assets/icons/mytpaLogo";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  changeCartSidebarStatus,
  connectWalletModalStatus,
} from "redux/reducers/modalsReducer";
import { CartIcon } from "utils/iconPack";
import { logoutUser } from "utils/userAuth";
import Menu from "./menu";
declare global {
  interface Window {
    ethereum: any;
  }
}
const Header = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const { user } = useAppSelector((state) => state);
  const { plaques } = useAppSelector((state) => state.cart.all);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <>
      <header className="px-[20px] root-container">
        <div className="flex items-center justify-between py-8">
          <MyTPALogo />
          <ul className="hidden text-white text-xl font-bold font-mabry md:flex items-center gap-[32px]">
            <a href="/">
              <li className="cursor-pointer">Home</li>
            </a>
            <a href="/blog">
              <li className="cursor-pointer">Blog</li>
            </a>
            <a href="https://mytpa.ca/blog/index.php/about-us/">
              <li className="cursor-pointer">About</li>
            </a>
            <a href="https://mytpa.ca/blog/index.php/contact-us/">
              <li className="cursor-pointer">Contact</li>
            </a>
          </ul>
          <div className="flex items-center ml-2">
            {user?.user ? (
              <div className="flex items-center">
                <span className="mr-3 text-white font-conthrax text-xs md:text-lg">
                  <span className="md:hidden">
                    {user.user.username.length > 9
                      ? user.user.username.slice(0, 6) + "..."
                      : user.user.username}
                  </span>
                  <span className="hidden md:block">{user.user.username}</span>
                </span>
                <button
                  className="p-[8px] md:py-[16px] md:px-[24px] rounded-[8px] text-white font-bold font-mabry text-[14px]"
                  style={{
                    background:
                      "linear-gradient(90deg, #F12711 0%, #F5AF19 100%)",
                  }}
                  onClick={() => {
                    logoutUser();
                    router("/");
                  }}
                >
                  log out
                </button>
              </div>
            ) : (
              <div
                className="p-[4px] rounded-[8px] border cursor-pointer"
                style={{
                  background: "rgba(255, 255, 255, 0.2)",
                  borderColor: "rgba(255, 255, 255, 0.4)",
                }}
              >
                <button
                  className="p-[8px] md:py-[16px] md:px-[24px] rounded-[8px] text-white font-bold font-mabry text-[12px] md:text-[14px]"
                  onClick={() => dispatch(connectWalletModalStatus(true))}
                  style={{
                    background:
                      "linear-gradient(90deg, #F12711 0%, #F5AF19 100%)",
                  }}
                >
                  Connect Wallet
                </button>
              </div>
            )}
            <button
              onClick={() => dispatch(changeCartSidebarStatus(true))}
              className="ml-[7px] hidden md:block md:ml-[32px] relative"
            >
              {plaques.length !== 0 && (
                <div
                  className="w-[16px] h-[16px] absolute rounded-full flex items-center justify-center text-[12px] -top-[8px] left-[16px]"
                  style={{
                    background:
                      "linear-gradient(90deg, #F12711 0%, #F5AF19 100%)",
                  }}
                >
                  {plaques.length}
                </div>
              )}
              <CartIcon />
            </button>
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="ml-[7px] md:ml-[32px] relative md:hidden"
            >
              <HamburgerIcon />
            </button>
          </div>
        </div>
      </header>
      <Menu
        close={() => setIsMenuOpen(false)}
        open={isMenuOpen}
        cartToggle={() => dispatch(changeCartSidebarStatus(true))}
      />
    </>
  );
};

export default Header;
