import { useEffect, useState } from 'react';

function useIsMobile() {
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    setIsMobileScreen(window.innerWidth <= 768);

    function handleResize() {
      setIsMobileScreen(window.innerWidth <= 768);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isMobileScreen };
}
export default useIsMobile;
