import { useForm } from "react-hook-form";

import { TextInput } from "components/form/textInput";
// import { Alert } from 'components/share-componenets/alert';
import { SelectBox } from "components/form/selectBox";
import { countries } from "utils/countries";
import { states } from "utils/states";

// import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { BillingInfoProps } from "intrefaces/billingInfo.interface";
// import { setBillingInfo } from 'redux/reducers/userReducer';
import { ccExpiresFormat, ccFormat } from "utils/form";
import { Request } from "api/request";
import { URLS } from "api/endpoints";
import { useState } from "react";
import { Alert } from "components/alert";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setTransactionId } from "redux/reducers/cartReducer";
import { refetchLookupData } from "redux/reducers/appReducer";

interface Props {
  setStep: (n: number) => void;
}

const BillingInfo: React.FC<Props> = ({ setStep }) => {
  const dispatch = useAppDispatch();

  const { plaques } = useAppSelector((state) => state.cart.purchase);
  const { pingAccount } = useAppSelector((state) => state.cart.purchase);

  const [loading, setLoading] = useState<boolean>(false);
  const [plaqueError, setPlaqueError] = useState<string>("");
  const [errorLabels, setErrorLabels] = useState<string[]>([]);
  const [dateError, setDateError] = useState<boolean>(false);
  const [cardError, setCardError] = useState<boolean>(false);
  const [cvcError, setCvcError] = useState<boolean>(false);

  const { handleSubmit, control, watch, setValue, setError, clearErrors } =
    useForm<BillingInfoProps>({
      mode: "onSubmit",
    });

  const onSubmit = async (data: BillingInfoProps) => {
    setErrorLabels([]);
    clearErrors();
    setDateError(false);
    setCardError(false);
    setCvcError(false);
    setLoading(true);
    try {
      dispatch(setTransactionId(""));
      console.log(data);
      const reqBody: any = { ...data };
      reqBody.username = pingAccount;
      reqBody.expire_month = Number(data.date.split("/")[0]);
      reqBody.expire_year = Number(data.date.split("/")[1]);
      reqBody.names = plaques.map((p) => p.name);
      const response = await Request.post(URLS.plaques.buy, reqBody);
      if (response) {
        dispatch(setTransactionId(response.data.hash));
        setStep(4);
        dispatch(refetchLookupData(true));
      }
    } catch (error) {
      const tempError: any = error;
      setLoading(false);
      if (tempError?.data && tempError?.data?.message.length > 0) {
        if (tempError?.data?.message[0] === "Your card number is incorrect.") {
          setCardError(true);
          setError("card_number", { message: "" });
          setValue("card_number", "");
        } else if (
          tempError?.data?.message[0] ===
          `Your card's expiration year is invalid.`
        ) {
          setDateError(true);
          setError("date", { message: "" });
          setValue("date", "");
        } else if (
          tempError?.data?.message[0].includes("cvc") ||
          tempError?.data?.message[0].includes("cvv")
        ) {
          setCvcError(true);
          setError("cvv", { message: "" });
          setValue("cvv", "");
        } else if (
          tempError.data.message ===
          "One or more of the requested plaques are not avaliable"
        ) {
          setPlaqueError(tempError.data.message);
        } else {
          tempError?.data?.message.map((item: string) =>
            setErrorLabels((prev) => [...prev, item])
          );
        }
      }
    }
  };

  return (
    <div className="mt-[24px] p-6 lg:p-8 pt-0">
      <div className="font-saira font-semibold text-3xl mb-[32px]">
        Enter card details:
      </div>
      {errorLabels.map((item: string, index) => (
        <Alert type="error" key={index} message={item} className="mt-4" />
      ))}
      {plaqueError && (
        <Alert type="error" message={plaqueError} className="mt-4" />
      )}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <TextInput
            name="cardHolderName"
            label={"Card Holder Name"}
            placeholder={"Enter Card Holder Name"}
            control={control}
            rules={{
              required: true,
            }}
            // defaultValue={billingInfo?.cardHolderName}
          />

          <div className="flex flex-col">
            <TextInput
              label={"Card Number"}
              name={"card_number"}
              placeholder={"Enter Card Number"}
              control={control}
              rules={{
                required: true,
                validate: (val: string) =>
                  /\d{4,16}/.test(val.replaceAll(" ", "")) ||
                  "Card number length must be between 4 and 16 digits.",
                onChange: (e) => {
                  setValue("card_number", ccFormat(e.target.value) || "");
                },
              }}
            />
            {cardError && (
              <span role="alert" className="inline-error">
                The card number is invalid
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <TextInput
              name="date"
              label={"Expiration Date"}
              placeholder="MM/YY"
              control={control}
              rules={{
                required: true,
                onChange: (e) => {
                  setValue("date", ccExpiresFormat(e.target.value));
                },
              }}
            />
            {dateError && (
              <span role="alert" className="inline-error">
                Invalid expiration date
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <TextInput
              name="cvv"
              label="CVC"
              placeholder={"Enter CVC"}
              control={control}
              rules={{
                required: true,
                onChange: (e) =>
                  setValue(
                    "cvv",
                    e.target.value.trim().replace(/[^0-9]/gi, "")
                  ),
              }}
            />
            {cvcError && (
              <span role="alert" className="inline-error">
                The CVC is invalid
              </span>
            )}
          </div>
        </div>
        <div className="font-saira text-base font-semibold mt-[24px] mb-[12px]">
          Billing Address:
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <TextInput
            name="first_name"
            label={"First Name"}
            placeholder={"Enter First Name"}
            control={control}
            rules={{
              required: true,
              onChange: (e) =>
                setValue(
                  "first_name",
                  e.target.value.trim().replace(/[\W\d]+/g, "")
                ),
            }}
            // defaultValue={billingInfo?.first_name}
          />
          <TextInput
            name="last_name"
            label={"Last Name"}
            placeholder={"Enter Last Name"}
            control={control}
            rules={{
              required: true,
              onChange: (e) =>
                setValue(
                  "last_name",
                  e.target.value.trim().replace(/[\W\d]+/g, "")
                ),
            }}
            // defaultValue={billingInfo?.last_name}
          />
        </div>
        <div className="grid grid-cols-1 gap-2 mt-2">
          <TextInput
            name="line1"
            label={"Address"}
            placeholder={"Enter Address"}
            control={control}
            rules={{
              required: true,
            }}
            // defaultValue={billingInfo?.line1}
          />
          <TextInput
            name="line2"
            label={"Apartment, suite, etc. (optional)"}
            placeholder={"Enter Apartment, suite, etc. (optional)"}
            control={control}
            // defaultValue={billingInfo?.line2}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-2">
          <TextInput
            name="city"
            label={"City"}
            placeholder={"Enter City"}
            control={control}
            rules={{
              required: true,
              onChange: (e) =>
                setValue("city", e.target.value.trim().replace(/[\W\d]+/g, "")),
            }}
            // defaultValue={billingInfo?.city}
          />
          <SelectBox
            name="country"
            label={"Country"}
            placeholder={"Select Country"}
            control={control}
            rules={{ required: true }}
            options={countries
              .filter((x) => x.name.toLowerCase() !== "iran")
              .map((x) => ({ label: x.name, value: x.iso2 }))}
            // defaultValue={billingInfo?.country}
          />
          <SelectBox
            name="state"
            label={"state / Province"}
            placeholder={"Select State"}
            control={control}
            rules={{ required: true }}
            options={
              watch().country
                ? states
                    .filter((x) => x.country_code === watch().country)
                    .map((x) => ({ label: x.name, value: x.state_code }))
                : []
            }
            // defaultValue={billingInfo?.state}
          />
          <TextInput
            name="postal_code"
            label="Zip Code / Postal Code"
            placeholder={"Enter Postal Code"}
            control={control}
            rules={{ required: true }}
            // defaultValue={billingInfo?.postal_code}
          />
        </div>
        <div className="w-full flex flex-row">
          <button
            type="submit"
            className="w-full text-white block bg-colorful bg-[#1d1f79] rounded-2xl text-xl font-medium text-center h-[48px] mt-[24px]"
          >
            {loading ? (
              <span
                className={`w-full h-full flex items-center justify-center relative ${
                  loading ? "block" : "hidden"
                }`}
              >
                Loading...
              </span>
            ) : (
              "Continue"
            )}
          </button>
        </div>
      </form>
      <style>
        {`
          .inline-error {
            color: #ff3131;
            font-family: Inter;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            width: 100%;
            margin-top: 8px;
          }
        `}
      </style>
    </div>
  );
};

export { BillingInfo };
