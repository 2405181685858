import { getCookie } from "cookies-next";
import { META_PROVIDER_NAME } from "constants/cookie";
import { getRequestedProvider } from "./auth";

export default async function createWeb3Instance() {
  const Web3 = (await import("web3")).default;
  let web3;
  if (
    typeof window !== "undefined" &&
    typeof window["ethereum" as keyof Window] !== "undefined"
  ) {
    let providerName = null as any;
    const providerNameCookie = getCookie(META_PROVIDER_NAME) as any;
    if (
      providerNameCookie !== null &&
      providerNameCookie !== "null" &&
      providerNameCookie !== undefined &&
      providerNameCookie
    ) {
      providerName = providerNameCookie;
    }
    const provider = await getRequestedProvider(providerName);
    web3 = new Web3(provider);
  } else {
    const givenProvider = new Web3.providers.HttpProvider(
      "https://sepolia.infura.io/v3/f2dc6eb7f7824c88bf4b82a120f8018d"
    );
    web3 = new Web3(givenProvider);
  }
  return web3;
}

