export const Success: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="99"
      height="98"
      viewBox="0 0 99 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.0884 51.3L44.8884 65.1L67.8884 37.5"
        stroke="white"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M49.5 95C74.9051 95 95.5 74.4051 95.5 49C95.5 23.5949 74.9051 3 49.5 3C24.0949 3 3.5 23.5949 3.5 49C3.5 74.4051 24.0949 95 49.5 95Z"
        stroke="url(#paint0_linear_10154_4638)"
        stroke-width="6"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10154_4638"
          x1="3.5"
          y1="49.7797"
          x2="95.5"
          y2="49.7797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F12711" />
          <stop offset="1" stop-color="#F5AF19" />
        </linearGradient>
      </defs>
    </svg>
  );
};
