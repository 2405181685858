// import { Question } from "src/assets/icons/Question";
// import { PlusIcon } from "src/assets/icons/plusIcon";
// import { MinusIcon } from "src/assets/icons/minusIcon";
// import { Failed } from "src/assets/icons/Failed";
// import { Success } from "src/assets/icons/Success";
import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Request } from "api/request";
import { URLS } from "api/endpoints";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Scrollbars from "react-custom-scrollbars-2";
import { refetchLookupData } from "redux/reducers/appReducer";
import { META_PROVIDER_NAME } from "constants/cookie";
import DebitIcon from "assets/icons/debitIcon";
import CryptoIcon from "assets/icons/cryptoIcon";
import SelectedDebitIcon from "assets/icons/selectedDebitIcon";
import SelectedCryptoIcon from "assets/icons/selectedCryptoIcon";
import { getCookie } from "cookies-next";
import { ProviderNames } from "constants/configs";
import { PlaqueItem } from "./components/plaqueItem";
import { CryptoCoinIcon, DebitCardIcon } from "utils/iconPack";
import { setPurchasePaymentMethod } from "redux/reducers/cartReducer";

interface AccountFormProps {
  setStep: (n: number) => void;
  setPingAccount: (p: string) => void;
  setInitCode: (p: string) => void;
  initCode: string;
  ping: string;
}

interface AccountFormData {
  account: string;
}

const AccountForm: React.FC<AccountFormProps> = ({
  setStep,
  ping,
  initCode,
  setInitCode,
  setPingAccount,
}) => {
  const dispatch = useAppDispatch();

  const { paymentMethod, plaques } = useAppSelector(
    (state) => state.cart.purchase
  );
  console.log(plaques);
  const { user } = useAppSelector((state) => state.user);
  const billingInfo = useAppSelector((state) => state.user.billingInfo);
  const { options } = useAppSelector((state) => state.modals.cartModal);

  const [error, setError] = useState("");
  const [canGet, setCanGet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [code, setCode] = useState("");
  const [reset, setReset] = useState(false);

  const [cryptoWidth, setCryptoWidth] = useState(170);
  const [debitWidth, setDebitWidth] = useState(250);

  const cryptoRef = useRef<HTMLDivElement>(null);
  const debitRef = useRef<HTMLDivElement>(null);

  const usdTotal = plaques.reduce(
    (pre, curr) => pre + Number(curr.priceUsd),
    0
  );
  const ethTotal = plaques.reduce(
    (pre, curr) => pre + Number(curr.priceEth),
    0
  );

  const {
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<AccountFormData>({
    defaultValues: {
      account: ping,
    },
  });

  const account = watch("account", ping);

  useEffect(() => {
    setError("");
    setCanGet(false);
  }, [account]);

  const sendEmailVerification = async () => {
    if (!user?.email) return;
    setLoading(true);
    try {
      const res = await Request.get(URLS.metamask.requestPrivateKey, {
        params: {
          email: user.email,
        },
      });
      if (res) {
        setStep(7);
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const onSubmit = async () => {
    if (paymentMethod === "debit") {
      setStep(1);
    } else {
      const providerNameCookie = (await getCookie(META_PROVIDER_NAME)) as any;
      if (
        providerNameCookie !== null &&
        providerNameCookie !== "null" &&
        providerNameCookie !== undefined &&
        providerNameCookie === ProviderNames.StageWallet
      ) {
        sendEmailVerification();
      } else setStep(3);
    }
  };

  useEffect(() => {
    if (!cryptoRef.current || !debitRef.current) return;
    setCryptoWidth(cryptoRef.current.offsetWidth);
    setDebitWidth(debitRef.current.offsetWidth);
  }, [cryptoRef, debitWidth]);

  return (
    <div className="-mt-6">
      <form onSubmit={handleSubmit(onSubmit)} className="p-5 lg:p-8 pt-0">
        <p className="font-semibold text-xl mb-5 text-white">
          Review purchase details
        </p>
        <div className="flex flex-col bg-[#FFFFFF29] px-[24px] py-[12px] rounded-lg">
          <span className="font-saira font-normal text-sm mb-[12px]">
            Notice:
          </span>
          <span className="text-sm leading-9">
            This process is non-refundable upon confirmation.
          </span>
        </div>
        {plaques.length > 1 ? (
          <div className="my-[20px]">
            <div
              className="text-base font-medium mt-[8px] underline cursor-pointer"
              onClick={() => setShowDetails(!showDetails)}
            >
              {!showDetails ? "Show details" : "Hide details"}
            </div>
            <div className="text-lg mt-5 mb-[4px]">Price</div>
            <div className="font-saira">
              <span className="text-xl font-semibold mr-2">
                $ {usdTotal.toFixed(2)}
              </span>
              {paymentMethod === "crypto" && (
                <span className="text-lg font-medium">
                  ( = {ethTotal.toFixed(6)} ETH )
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className="my-[32px]">
            <PlaqueItem
              imgUrl={plaques[0]?.image}
              name={plaques[0]?.name}
              usdPrice={Number(plaques[0]?.priceUsd)}
              ethPrice={Number(plaques[0]?.priceEth)}
            />
          </div>
        )}

        {!options && (
          <>
            <p className="text-xs mt-[20px] text-white">Payment Method</p>

            {paymentMethod === "crypto" ? (
              <div className="flex items-center">
                <CryptoCoinIcon
                  className="h-[24px] w-[24px]"
                  width={cryptoWidth}
                />
                <span className="text-sm font-medium ml-[8px]">{"Crypto"}</span>
              </div>
            ) : (
              <div className="flex items-center">
                <DebitCardIcon
                  className="h-[24px] w-[24px]"
                  width={cryptoWidth}
                />
                <span className="text-sm font-medium ml-2">
                  {"Credit or Debit Card"}
                </span>
              </div>
            )}
          </>
        )}

        {options && (
          <div className="mt-6">
            <span className="text-xl mt-6">Select a payment method</span>
            <div className="flex lg:items-center relative items-start lg:justify-between mt-4 lg:flex-row flex-col">
              <div
                ref={debitRef}
                className="cursor-pointer relative"
                onClick={() => dispatch(setPurchasePaymentMethod("debit"))}
              >
                {paymentMethod === "debit" ? (
                  <SelectedDebitIcon width={debitWidth} />
                ) : (
                  <DebitIcon width={debitWidth} />
                )}
              </div>
              <div
                className="cursor-pointer mt-[4px] md:mt-0"
                onClick={() => dispatch(setPurchasePaymentMethod("crypto"))}
              >
                {paymentMethod === "crypto" ? (
                  <SelectedCryptoIcon width={cryptoWidth} />
                ) : (
                  <CryptoIcon width={cryptoWidth} />
                )}
              </div>
            </div>
          </div>
        )}

        {showDetails && (
          <>
            <div className="font-semibold mt-[24px]">{"Plaque List"}</div>
            <div className="mt-[16px] h-[208px] ">
              <Scrollbars universal>
                {plaques.map((p, index) => (
                  <div key={index} className="my-[8px] mr-[8px]">
                    <PlaqueItem
                      ethPrice={Number(p.priceEth)}
                      usdPrice={Number(p.priceUsd)}
                      imgUrl={p.image}
                      name={p.name}
                    />
                  </div>
                ))}
              </Scrollbars>
            </div>
          </>
        )}

        <button
          type="submit"
          id="confirm-btn-id"
          className="w-full text-white block bg-colorful  rounded-2xl text-xl font-medium text-center mt-[20px] h-[48px] confirm-btn"
        >
          {loading ? (
            <span
              className={`w-full h-full flex items-center justify-center relative ${
                loading ? "block" : "hidden"
              }`}
            >
              Loading...
            </span>
          ) : (
            "Continue"
          )}
        </button>

        <style>{`
          .input-wrapper {
            border-radius: 10px;
            padding: 2px;
          }
          .question-button {
            right: 18px;
            top: 14px;
          }
          @media (max-width: 450px) {
            .question-button {
              right: 0px;
              top: 14px;
            }
          }
          .tooltip::after {
            content: '';
            width: 10px;
            height: 10px;
            background: white;
            position: absolute;
            right: 12px;
            bottom: -5px;
            transform: rotateZ(45deg);
          }
          .css-1u9des2-indicatorSeparator {
            display: none !important;
          }
          .confirm-btn {
          }
        `}</style>
      </form>
    </div>
  );
};

export { AccountForm };
