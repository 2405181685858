import * as React from 'react';
import { SVGProps } from 'react';

const SVGComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={23}
    viewBox="0 0 16 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M10.449 2.69978H10.4545H10.449ZM10.449 2.69978L7.69927 9.29912L13.1987 12.4866L4.94955 20.298L7.69927 13.6987L2.19982 10.509L10.449 2.69978ZM10.449 0.500001C9.88489 0.502546 9.34279 0.719152 8.93225 1.10604L0.686375 8.90976C0.437811 9.14523 0.247705 9.43552 0.131217 9.75748C0.0147301 10.0794 -0.0249157 10.4242 0.015442 10.7642C0.0946341 11.4527 0.496094 12.0642 1.09553 12.4118L4.92535 14.6347L2.91695 19.4533C2.77832 19.7877 2.72424 20.1512 2.75951 20.5115C2.79477 20.8718 2.91829 21.2179 3.11914 21.5191C3.31999 21.8203 3.59197 22.0674 3.91104 22.2385C4.2301 22.4096 4.58641 22.4994 4.94845 22.5C5.49619 22.5 6.04064 22.2954 6.4608 21.8973L14.71 14.0847C14.9586 13.8495 15.1487 13.5595 15.2652 13.2377C15.3817 12.9159 15.4213 12.5713 15.3809 12.2314C15.3414 11.8917 15.2232 11.5659 15.0357 11.2799C14.8482 10.9938 14.5966 10.7555 14.3008 10.5838L10.471 8.36421L12.4387 3.64239C12.5978 3.30693 12.6694 2.93667 12.6468 2.56608C12.6243 2.1955 12.5083 1.83666 12.3097 1.52298C12.111 1.20931 11.8363 0.951004 11.5109 0.772134C11.1856 0.593263 10.8203 0.499645 10.449 0.500001Z"
      fill="white"
    />
  </svg>
);
export default SVGComponent;
