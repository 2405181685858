interface Props {
  setStep: (n: number) => void;
}

const PurchaseFailed: React.FC<Props> = ({ setStep }) => {
  return (
    <div className="mt-[24px] pb-[32px]">
      <div className="flex flex-col justify-center px-5 lg:px-8">
        <p className="text-2xl font-semibold font-saira relative text-white">
          Purchase Failed
        </p>
        <p className="my-14 text-white">
          Check the payment method you entered and try again.
        </p>

        <button
          className="w-full h-[48px] bg-colorful rounded-2xl bg-[#1d1f79]"
          onClick={() => setStep(0)}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export { PurchaseFailed };
