import { FC } from "react";

const HamburgerIcon: FC = () => {
  return (
    <svg
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.2C0 0.88174 0.126428 0.576516 0.351472 0.351472C0.576515 0.126428 0.88174 0 1.2 0H22.8C23.1183 0 23.4235 0.126428 23.6485 0.351472C23.8736 0.576516 24 0.88174 24 1.2C24 1.51826 23.8736 1.82348 23.6485 2.04853C23.4235 2.27357 23.1183 2.4 22.8 2.4H1.2C0.88174 2.4 0.576515 2.27357 0.351472 2.04853C0.126428 1.82348 0 1.51826 0 1.2ZM0 8.4384C0 8.12014 0.126428 7.81491 0.351472 7.58987C0.576515 7.36483 0.88174 7.2384 1.2 7.2384H22.8C23.1183 7.2384 23.4235 7.36483 23.6485 7.58987C23.8736 7.81491 24 8.12014 24 8.4384C24 8.75666 23.8736 9.06188 23.6485 9.28693C23.4235 9.51197 23.1183 9.6384 22.8 9.6384H1.2C0.88174 9.6384 0.576515 9.51197 0.351472 9.28693C0.126428 9.06188 0 8.75666 0 8.4384ZM1.2 14.4768C0.88174 14.4768 0.576515 14.6032 0.351472 14.8283C0.126428 15.0533 0 15.3585 0 15.6768C0 15.9951 0.126428 16.3003 0.351472 16.5253C0.576515 16.7504 0.88174 16.8768 1.2 16.8768H22.8C23.1183 16.8768 23.4235 16.7504 23.6485 16.5253C23.8736 16.3003 24 15.9951 24 15.6768C24 15.3585 23.8736 15.0533 23.6485 14.8283C23.4235 14.6032 23.1183 14.4768 22.8 14.4768H1.2Z"
        fill="white"
      />
    </svg>
  );
};

export default HamburgerIcon;
