import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Request } from "api/request";
import { URLS } from "api/endpoints";
import { useAppSelector } from "redux/hooks";
import { Verification } from "components/form/verificationInput";

interface Props {
  setStep: (n: number) => void;
  setPrivateKey: (p: string) => void;
}

interface CreateFormData {
  code: string;
}

const RESEND_CODE_TIME_LIMIT = 60 as any;
const VerificationCode: React.FC<Props> = ({ setStep, setPrivateKey }) => {
  const user = useAppSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  // const [email, setEmail] = useState('');

  const [resendLoading, setResendLoading] = useState(false);

  const [countDown, setCountDown] = useState(RESEND_CODE_TIME_LIMIT);

  const { control, reset, handleSubmit, setError, setValue } =
    useForm<CreateFormData>();

  useEffect(() => {
    const interval = setTimeout(() => {
      if (countDown) setCountDown(countDown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDown]);

  async function resendCode() {
    reset();
    setValue("code", "");
    setResendLoading(true);

    const emailVerify = await Request.get(URLS.metamask.requestPrivateKey, {
      params: { email: user?.email },
    });
    if (emailVerify) {
      setCountDown(RESEND_CODE_TIME_LIMIT);
    }
    setResendLoading(false);
  }

  const onSubmit = async (data: CreateFormData) => {
    setLoading(true);
    const emailVerifyCode = await Request.get(URLS.metamask.privateKey, {
      params: {
        code: data.code,
        email: user?.email,
      },
    }).catch((err: any) => {
      setError("code", { message: err?.data?.message, type: "required" });
    });
    if (emailVerifyCode) {
      setPrivateKey(emailVerifyCode?.data);
      setStep(3);
    }
    setLoading(false);
  };

  return (
    <div className="mt-[24px] pb-[32px]">
      <div className="flex flex-col justify-center px-5 lg:px-8 items-center">
        <p className="text-3xl font-semibold font-saira relative text-white">
          Enter verification code
        </p>

        <span className=" text-xs md:text-lg text-[#E5E5E5] font-normal text-center mb-8 mt-14">
        Enter the code that was sent to your email inbox.
        </span>
        <form className="w-5/6 md:w-[384px] mb-7" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full flex flex-col items-center mb-3">
            <Verification
              control={control}
              name="code"
              rules={{
                required: "Please enter verification code.",
              }}
            />
          </div>
          <div className="text-center flex justify-center items-center w-full mt-4 text-sm">
            {countDown ? (
              <span className="text-lg">
                (0
                {countDown === RESEND_CODE_TIME_LIMIT
                  ? Math.floor(RESEND_CODE_TIME_LIMIT / 60)
                  : 0}
                :
                {RESEND_CODE_TIME_LIMIT - countDown === 0
                  ? "00"
                  : RESEND_CODE_TIME_LIMIT - countDown >
                    RESEND_CODE_TIME_LIMIT - 10
                  ? `0${countDown}`
                  : countDown}
                )
              </span>
            ) : (
              <button onClick={resendCode} disabled={resendLoading}>
                <span className="font-light underline underline-offset-2 text-lg">
                  Resend code
                </span>
              </button>
            )}
          </div>
          <button
            className="w-full h-[48px] bg-colorful rounded-2xl mt-12 bg-[#1d1f79]"
            type="submit"
          >
            {loading ? (
              <span
                className={`w-full h-full flex items-center justify-center relative ${
                  loading ? "block" : "hidden"
                }`}
              >
                Loading...
              </span>
            ) : (
              <span className="text-white text-xl font-semibold ">
                Continue
              </span>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export { VerificationCode };
