import { useAppSelector } from "redux/hooks";
import { copyToClipboard } from "utils/common";
import { useEffect, useState } from "react";
import { ToastSuccessIcon } from "utils/iconPack";
import { PlaqueImage } from "components/plaque-image";

const ShareLink = () => {
  const selected = useAppSelector((state) => state.modals.getTPALink.data);

  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);

  useEffect(() => {
    if (isCopiedToClipboard) {
      setTimeout(() => {
        setIsCopiedToClipboard(false);
      }, 2000);
    }
  }, [isCopiedToClipboard]);

  return (
    <>
      <div className="flex flex-row items-center justify-start mt-[32px] mb-[32px]">
        <div>
          <div className="w-20 h-20">
            <PlaqueImage src={selected?.image} name={selected?.name} />
          </div>
        </div>
        <div className="flex flex-col ml-4">
          <div className="font-normal text-xs">TPA</div>
          <div className="font-semibold text-xl font-saira">
            {selected?.name?.toUpperCase()}
          </div>
        </div>
      </div>
      <div
        className="box w-full py-3 px-6  flex flex-row justify-between font-saira"
        style={{ background: "rgba(255, 255, 255, 0.12)" }}
      >
        <div>{`https://stagemeta.world/tpa/${selected?.name}`}</div>
        {isCopiedToClipboard ? (
          <div className="flex flex-row items-center justify-center cursor-pointer">
            <ToastSuccessIcon />
          </div>
        ) : (
          <div
            className="flex flex-row items-center justify-center cursor-pointer"
            onClick={() => {
              copyToClipboard(`https://stagemeta.world/tpa/${selected?.name}`);
              setIsCopiedToClipboard(true);
            }}
          >
            <img
              src="/assets/copy-icon.png"
              className="w-[20px] h-[20px] mr-1"
              alt=""
            />
            <div>copy</div>
          </div>
        )}
      </div>
      <style>{`
        .box {
          background: rgba(255, 255, 255, 0.12);
          border-image: linear-gradient(
            160deg,
            #F12711 20%,
            #F12711 40%,
            #F5AF19 85%
          );
          border-width: 1px;
          border-image-slice: 1;
        }
      `}</style>
    </>
  );
};

export default ShareLink;
