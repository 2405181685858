import {
  checkWalletConditionsInitial,
  subscribeToWeb3Events,
} from "blockchain/auth";
import { lazy, Suspense, useEffect } from "react";
// import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "components/layout";
import Modals from "components/modals";

import { checkUser } from "utils/userAuth";
import { initCart } from "cart/functions";

const HomePage = lazy(() => import("./pages/home"));

function App() {
  useEffect(() => {
    initCart();
    checkUser(true);
    checkWalletConditionsInitial();
    subscribeToWeb3Events(null, true);
  }, []);

  return (
    <div className="bg-[#0F0C29] w-min-screen h-min-screen">
      <div>
        <Suspense fallback={null}>
          <Layout>
            <Modals />
            <HomePage />
          </Layout>
        </Suspense>
      </div>
    </div>
  );
}

export default App;

