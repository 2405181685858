import * as React from "react";
import { SVGProps } from "react";

const SVGComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_10144_4218)">
      <path
        d="M2.66667 3.3335C1.95942 3.3335 1.28115 3.61445 0.781049 4.11454C0.280952 4.61464 0 5.29292 0 6.00016L0 18.0002C0 18.7074 0.280952 19.3857 0.781049 19.8858C1.28115 20.3859 1.95942 20.6668 2.66667 20.6668H21.3333C22.0406 20.6668 22.7189 20.3859 23.219 19.8858C23.719 19.3857 24 18.7074 24 18.0002V6.00016C24 6.00016 24 3.3335 21.3333 3.3335H2.66667Z"
        fill="#FFAC33"
      />
      <path d="M0 6.6665H24V9.99984H0V6.6665Z" fill="#292F33" />
      <path
        d="M2.66602 12.6665H21.3327V16.6665H2.66602V12.6665Z"
        fill="#F4F7F9"
      />
      <path
        d="M12.6671 16.0002C11.5318 16.0002 11.1065 15.1935 11.0211 14.7995C10.6565 14.8268 10.3011 15.0015 9.8178 15.3088C9.3078 15.6328 8.7298 16.0002 8.00046 16.0002C7.20246 16.0002 6.7178 15.5502 6.66713 14.6668C6.66513 14.6295 6.69246 14.5415 6.68113 14.5415C5.44246 14.5415 4.54646 15.7155 4.5378 15.7275C4.4862 15.7982 4.42112 15.8581 4.34632 15.9036C4.27152 15.9491 4.18846 15.9794 4.10192 15.9927C4.01538 16.006 3.92706 16.0021 3.84203 15.9812C3.757 15.9603 3.67695 15.9228 3.60646 15.8708C3.53585 15.8191 3.47612 15.7539 3.4307 15.679C3.38527 15.6041 3.35504 15.5211 3.34173 15.4345C3.32842 15.348 3.33229 15.2597 3.35312 15.1746C3.37395 15.0895 3.41133 15.0094 3.46313 14.9388C3.51446 14.8688 4.74046 13.3335 6.66713 13.3335C7.95846 13.3335 8.03246 14.2502 8.05246 14.5922L8.05713 14.6648C8.38113 14.6422 8.7178 14.4288 9.1038 14.1828C9.74446 13.7762 10.5391 13.2688 11.6038 13.5415C12.1845 13.6902 12.2751 14.2288 12.3098 14.4322C12.3185 14.4828 12.3311 14.5588 12.3425 14.5828C12.3471 14.5828 12.4398 14.6435 12.7271 14.6695C13.2738 14.7195 13.8745 14.4835 14.5105 14.2342C15.1691 13.9755 15.8505 13.7088 16.5845 13.7088C18.8438 13.7088 19.7625 14.7988 19.8598 14.9228C19.9158 14.9916 19.9575 15.0708 19.9825 15.1558C20.0075 15.2409 20.0153 15.3301 20.0054 15.4182C19.9955 15.5063 19.9682 15.5915 19.9249 15.6689C19.8817 15.7463 19.8235 15.8143 19.7536 15.8689C19.6838 15.9236 19.6038 15.9637 19.5183 15.987C19.4327 16.0103 19.3434 16.0164 19.2555 16.0048C19.1676 15.9931 19.0829 15.9641 19.0064 15.9194C18.9299 15.8746 18.863 15.8151 18.8098 15.7442C18.7938 15.7262 18.1898 15.0415 16.5851 15.0415C16.1031 15.0415 15.5665 15.2515 14.9978 15.4742C14.3251 15.7382 13.3751 16.0002 12.6671 16.0002Z"
        fill="#8899A6"
      />
    </g>
    <defs>
      <clipPath id="clip0_10144_4218">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
