import { FC, useRef } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  setPingAccount,
  setPurchasePaymentMethod,
} from "redux/reducers/cartReducer";
import {
  CryptoIcon,
  DebitIcon,
  SelectedCryptoIcon,
  SelectedDebitIcon,
} from "utils/iconPack";
import { Refetch } from "assets/icons/refetch";
import {
  cleanAllCarts,
  purchase,
  removeFromCart,
  updateCartInfo,
} from "cart/functions";
import { CartPlaque } from "./cartPlaque";

// interface Props {}

const CartSidebar: FC = () => {
  const dispatch = useAppDispatch();
  const { paymentMethod } = useAppSelector((state: any) => state.cart.purchase);
  const { plaques, loading } = useAppSelector((state: any) => state.cart.all);

  const dimension = useRef<HTMLDivElement>(null);

  function getTotalPrice(allPlaques: any, type: "eth" | "usd") {
    let price = 0;
    allPlaques.map((p: any) => {
      if (type === "eth") {
        price += Number(p.priceEth);
      } else {
        price += Number(p.priceUsd);
      }
      return p;
    });
    return price.toFixed(2);
  }

  function needRefresh(allPlaques: any) {
    let need = false;
    allPlaques.map((p: any) => {
      if (p.status !== "AVAILABLE") {
        need = true;
      }
      return p;
    });
    return need;
  }

  return (
    <div className="flex flex-col justify-between h-full pb-9">
      <div className="flex justify-between items-center px-8">
        <span className="font-semibold">
          {plaques.length} {`item${plaques.length > 1 ? "s" : ""}`}
        </span>
        <button onClick={() => cleanAllCarts()} className="text-sm font-medium">
          {"clear all"}
        </button>
      </div>
      <div className="mt-4 h-full">
        <Scrollbars universal>
          {plaques.map((p: any, index: number) => (
            <CartPlaque
              key={index}
              imgUrl={p.image}
              name={p.name}
              price={p.priceUsd}
              onDeleteIconClick={() => {
                removeFromCart("all", p.name);
              }}
              removeOnly={p.status !== "AVAILABLE"}
            />
          ))}
        </Scrollbars>
      </div>

      <div className="px-8 ">
        <div className="flex justify-between items-center border-t-[1px] border-[#D5D5D5] pt-5">
          <span className="font-semibold">{"Total price"}</span>
          <span className="font-semibold">
            ${getTotalPrice(plaques, "usd")}
          </span>
        </div>

        {paymentMethod === "crypto" && (
          <div className="text-xs text-[#A3A3A3] text-right mt-1">
            {getTotalPrice(plaques, "eth")} ETH
          </div>
        )}

        <div className="flex flex-col mt-8">
          <span className="text-sm">{"Select a payment method:"}:</span>
          <div
            ref={dimension}
            className="cursor-pointer mb-2 mt-4"
            onClick={() => dispatch(setPurchasePaymentMethod("crypto"))}
          >
            {paymentMethod === "crypto" ? (
              <SelectedCryptoIcon width={dimension.current?.offsetWidth} />
            ) : (
              <CryptoIcon width={dimension.current?.offsetWidth} />
            )}
          </div>
          <div
            className="cursor-pointer"
            onClick={() => dispatch(setPurchasePaymentMethod("debit"))}
          >
            {paymentMethod === "debit" ? (
              <SelectedDebitIcon width={dimension.current?.offsetWidth} />
            ) : (
              <DebitIcon width={dimension.current?.offsetWidth} />
            )}
          </div>
        </div>
        <button
          onClick={() => {
            if (needRefresh(plaques)) {
              updateCartInfo("all", true);
              dispatch(setPingAccount(""));
            } else {
              purchase();
            }
          }}
          className="font-medium bg-button w-full mt-8 rounded-2xl flex justify-center items-center"
        >
          {loading ? (
            <>
              <span
                className={`w-full h-full flex items-center justify-center relative `}
              >
                <span className="dot-flashing mx-auto"></span>
              </span>
            </>
          ) : (
            <>
              {!needRefresh(plaques) ? (
                "Complete Purchase"
              ) : (
                <span className="flex ">
                  <Refetch /> <span className="ml-2">{"refresh"}</span>
                </span>
              )}
            </>
          )}
        </button>
      </div>
      <style>{`
      .bg-button {
        background: linear-gradient(90deg, #F12711 0%, #F5AF19 100%);
        height : 48px;
      }
      `}</style>
    </div>
  );
};

export default CartSidebar;
