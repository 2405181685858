import { useEffect } from "react";

export interface ModalProps {
  show: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  maxWidth?: number;
  className?: string;
  overflow?: boolean;
  bgRightShadow?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  show,
  onClose,
  maxWidth,
  className,
  overflow = true,
  bgRightShadow,
}) => {
  const handleClose = () => {
    if (!onClose) return;
    onClose();
  };

  const onWrapperClick = (id: string) => {
    if (onClose && id === "modal-wrapper") handleClose();
  };

  useEffect(() => {
    if (show) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [show]);

  if (!show) return null;
  return (
    <>
      <div
        className={`fixed bg-black bg-opacity-20 backdrop-blur-sm top-0 left-0 right-0 bottom-0 z-[3000] overflow-y-auto flex items-center p-3 md:p-8`}
        onClick={(e: any) => onWrapperClick(e.target.id)}
        id="modal-wrapper"
      >
        <div
          className={`w-full max-w-[768px] m-auto bg-[#05050D] text-white relative ${
            overflow ? "overflow-hidden" : ""
          } ${className} `}
          style={{ maxWidth, borderRadius: 16 }}
        >
          <div className={bgRightShadow ? "right-shadow" : "left-shadow"} />

          <button onClick={handleClose} className="absolute right-6 top-6 z-10">
            <img className="w-[20px] h-[20px]" src="assets/close-white.png" alt="close" />
          </button>

          <div className="relative pt-12">{children}</div>
        </div>
      </div>
      <style>
        {`
          .left-shadow {
            position: absolute;
            width: 614.84px;
            height: 669.47px;
            left: -30%;
            top: -100px;

            background: #141549;
            filter: blur(150px);
            transform: matrix(-0.58, -0.81, -0.81, 0.58, 0, 0);
          }
          .right-shadow {
            position: absolute;
            width: 614.84px;
            height: 669.47px;
            left: 50%;
            top: -200px;

            background: linear-gradient(
              180deg,
              rgba(20, 21, 73, 1) 0%,
              rgba(20, 21, 73, 0.9) 100%
            );
            filter: blur(150px);
            border-radius: 233.5px;
            transform: rotate(-54.37deg);
          }
        `}
      </style>
    </>
  );
};

export default Modal;
