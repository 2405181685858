import * as React from 'react';
import { SVGProps } from 'react';

interface SVGComponentProps extends SVGProps<SVGSVGElement> {
  rx?: string | number;
}

const SVGComponent = (props: SVGComponentProps) => (
  <svg
    width="100%"
    height="100%"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect
      x={1}
      y={1}
      width="99%"
      height="97%"
      rx={props.rx !== undefined ? props.rx : 15}
      stroke="url(#paint0_linear_5461_21124)"
      strokeWidth={2}
    />
    <defs>
      <linearGradient
        id="paint0_linear_5461_21124"
        x1={0}
        y1={0}
        x2={77.9228}
        y2={193.309}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#F12711" />
        <stop offset={0.536458} stopColor="#F12711" />
        <stop offset={1} stopColor="#F5AF19" />
      </linearGradient>
    </defs>
  </svg>
);
export default SVGComponent;
