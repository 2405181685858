import { FC } from "react";

const MyTPALogo: FC = () => {
  return (
    <svg
      width="140"
      height="32"
      viewBox="0 0 140 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 30.9026V1H4.11688L16.1948 22.039L28.4675 1H31.3896V30.9026H27.5909V9.18182L17.2662 27.2013H15.2208L4.6039 9.18182V30.9026H1Z"
        fill="white"
      />
      <path
        d="M38.3052 1H35.6753L34.9935 2.26623L46.3896 20.4805V30.9026H49.8961V20.4805L59.8312 4.6039V1H57.9805L48.2403 16.974L38.3052 1Z"
        fill="white"
      />
      <path
        d="M73.8571 31H70.1558L70.0584 4.50649H59.8312V1H83.987V4.50649H73.8571V31Z"
        fill="#E5E5E5"
      />
      <path
        d="M120.513 10.5455L124.604 2.07143L128.89 10.5455H120.513Z"
        fill="#E5E5E5"
      />
      <path
        d="M117.494 16.3896L119.247 12.8831H130.058L131.909 16.3896H117.494Z"
        fill="#E5E5E5"
      />
      <path
        d="M114.669 22.2338L116.325 18.7273H132.883L134.636 22.2338H114.669Z"
        fill="#E5E5E5"
      />
      <path
        d="M110.578 30.3182L113.5 24.474H135.805L138.727 30.3182H110.578Z"
        fill="#E5E5E5"
      />
      <path
        d="M91.0974 31V21.6493H99.8636C99.8636 21.6493 110.481 21.6493 110.481 11.3247C110.481 1 99.8636 1 99.8636 1H87.3961V4.6039H99.8636C99.8636 4.6039 106.779 4.6039 106.779 11.3734C106.779 18.1429 99.8636 18.1429 99.8636 18.1429H87.3961V31H91.0974Z"
        fill="#E5E5E5"
      />
      <path
        d="M59.8312 1H57.9805L48.2403 16.974L38.3052 1H35.6753L34.9935 2.26623L46.3896 20.4805V30.9026H49.8961V20.4805L59.8312 4.6039V1ZM59.8312 1H83.987V4.50649H73.8571V31H70.1558L70.0584 4.50649H59.8312V1ZM1 1V30.9026H4.6039V9.18182L15.2208 27.2013H17.2662L27.5909 9.18182V30.9026H31.3896V1H28.4675L16.1948 22.039L4.11688 1H1ZM124.604 2.07143L120.513 10.5455H128.89L124.604 2.07143ZM119.247 12.8831L117.494 16.3896H131.909L130.058 12.8831H119.247ZM116.325 18.7273L114.669 22.2338H134.636L132.883 18.7273H116.325ZM113.5 24.474L110.578 30.3182H138.727L135.805 24.474H113.5ZM91.0974 21.6494V31H87.3961V18.1429H99.8636C99.8636 18.1429 106.779 18.1429 106.779 11.3734C106.779 4.6039 99.8636 4.6039 99.8636 4.6039H87.3961V1H99.8636C99.8636 1 110.481 1 110.481 11.3247C110.481 21.6494 99.8636 21.6494 99.8636 21.6494H91.0974Z"
        stroke="#E5E5E5"
      />
    </svg>
  );
};

export default MyTPALogo;
