import { store } from "redux/store";
import { setError, Types } from "redux/reducers/cartReducer";
import { CART } from "constants/localstorage";
import { checkUser } from "utils/userAuth";
import { URLS } from "api/endpoints";
import { Request } from "api/request";
import { connectWalletModalStatus } from "redux/reducers/modalsReducer";
import { toast } from "redux/reducers/appReducer";

const checkAlreadyInCart = (
  type: "all" | "purchase" | "unAssign",
  plaqueName: string | null,
  message: boolean
) => {
  const cart = JSON.parse(localStorage.getItem(CART) as string) as Types;
  if (cart && cart.all && cart.purchase && cart.unAssign) {
    if (cart[type].plaques.findIndex((x) => x.name === plaqueName) !== -1) {
      if (message) {
        store.dispatch(
          toast({
            show: true,
            message: "Already added to cart",
            type: "success",
          })
        );
      }
      return true;
    }
  }
  return false;
};

const checkBeforeAdd = async (
  plaqueName: string | null,
  type: "all" | "purchase" | "unAssign",
  message: boolean
) => {
  const isAlreadyInCart = checkAlreadyInCart(type, plaqueName, message);
  if (isAlreadyInCart) {
    return null;
  }
  const response = await Request.get(URLS.plaques.lookUp, {
    params: {
      name: plaqueName,
    },
  });
  if (response?.data) {
    const { data } = response;
    let result = {} as any;
    result = data;
    const preparePlaque = await Request.post(
      URLS.plaques.prepare(plaqueName as string),
      {}
    );
    if (preparePlaque?.data) {
      result = {
        ...data,
        ...preparePlaque?.data,
      };
    }
    result.priceEth = Number(data?.priceEth)?.toFixed(2);
    result.priceUsd = Number(data?.priceUsd)?.toFixed(2);
    result.networkEnergyEth = Number(data?.networkEnergyEth)?.toFixed(7);

    // dynamic cart :  replace apis with checkItem api server

    if (result?.status !== "AVAILABLE") {
      return false;
    }
    return result;
  }
  return null;
};

const checkAuthFunc = async () => {
  const isUserAuth = await checkUser(false);
  return isUserAuth;
};

const checkAuthWithModal = async (type: "all" | "purchase" | "unAssign") => {
  const isUserAuth = await checkUser(false);
  if (isUserAuth === false) {
    store.dispatch(
      setError({
        type,
        error: `User is not logged in`,
      })
    );
    store.dispatch(connectWalletModalStatus(true));
    return false;
  }
  return isUserAuth;
};

const applyMiddleware = async (
  type: "all" | "purchase" | "unAssign",
  callback: any,
  plaqueName: string | null,
  checkAuth = false,
  withMessage = true
) => {
  let authPass = false;
  let addPass = false;
  if (checkAuth) {
    const isUserAuth = await checkAuthFunc();
    if (isUserAuth) {
      authPass = true;
    }
  } else {
    authPass = true;
  }
  if (plaqueName) {
    const checkBeforeAddResponse = await checkBeforeAdd(
      plaqueName,
      type,
      withMessage
    );
    if (checkBeforeAddResponse) {
      addPass = true;
      callback(checkBeforeAddResponse);
    }
  }
  if (authPass === false) {
    store.dispatch(
      setError({
        type,
        error: `User is not logged in`,
      })
    );
    store.dispatch(connectWalletModalStatus(true));
    return false;
  }
  if (addPass === false) {
    store.dispatch(
      setError({
        type,
        error: `Can't add this plaque to cart`,
      })
    );
    return false;
  }
  return true;
};

export {
  checkBeforeAdd,
  applyMiddleware,
  checkAuthFunc,
  checkAuthWithModal,
  checkAlreadyInCart,
};

