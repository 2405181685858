import { Request } from "api/request";
import { URLS } from "api/endpoints";
import ContractInstance from "./index";
import createWeb3Instance from "./web3";
import { checkBalance, getAccountMiddleware } from "./helper";

const contract = new ContractInstance();

const getPlaqueDetail = async () => {
  try {
    const web3 = await createWeb3Instance();
    const instance = await contract.getContract("", web3);
    return instance?.methods?.getPlaque(1).call();
  } catch (e) {
    return Promise.reject(e);
  }
};

const getPlaquesList = async () => {
  try {
    const web3 = await createWeb3Instance();
    const instance = await contract.getContract("", web3);
    const account = await getAccountMiddleware(false, web3, "");
    return instance?.methods?.totalPlaques().call({
      from: account,
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

const sendETH = async (amount: number, to: string, privateKey: string) => {
  const web3 = await createWeb3Instance();
  const amountInWei = web3.utils.toWei(amount.toString(), "ether");

  const transaction = {
    to,
    value: amountInWei,
    gas: 21000,
  };

  const signedTx = await web3.eth.accounts.signTransaction(
    transaction,
    privateKey
  );

  return web3.eth.sendSignedTransaction(
    signedTx.rawTransaction || "",
    (error, hash) => {
      if (!error) {
        console.log(
          "🎉 The hash of your transaction is: ",
          hash,
          "\n Check Alchemy's Mempool to view the status of your transaction!"
        );
      } else {
        console.log(
          "❗Something went wrong while submitting your transaction:",
          error
        );
      }
    }
  );
};

// const changeNetwork = async (chainId: any) => {
//   if (
//     typeof window !== 'undefined' &&
//     typeof window['ethereum' as keyof Window] !== 'undefined'
//   ) {
//     const win = window as any;
//     const web3 = await createWeb3Instance();
//     const netId = await web3.eth.net.getId();
//     console.log(netId);
//     try {
//       await win.ethereum.request({
//         method: 'wallet_switchEthereumChain',
//         params: [{ chainId: web3.utils.toHex(chainId) }],
//       });
//     } catch (error) {
//       console.error(error);
//     }
//   }
// };

const getGasOfSend = async () => {
  const web3 = await createWeb3Instance();
  const gasprice = await web3.eth.getGasPrice();
  const gas = Number(gasprice) * 30000;

  return Number(web3.utils.fromWei(gas.toString(), "ether"));
};

const mintAndTransferNFT = async (
  plaqueNames: string[],
  amounts: number[],
  outputData: string,
  priceList: string,
  callback: () => void,
  privateKey: string
) => {
  try {
    const web3 = await createWeb3Instance();
    const instance = await contract.getContract("", web3);
    const account = await getAccountMiddleware(true, web3, privateKey);
    callback();

    const gasprice = await web3.eth.getGasPrice();
    const gasPrice = Math.round(Number(gasprice) * 1.4);

    plaqueNames = plaqueNames.map((name) => {
      const hexString = web3.utils.stringToHex(name);
      return hexString + "0".repeat(66 - hexString.length);
    });

    const gas = await instance.methods
      .mintTransfer(plaqueNames, amounts, outputData)
      .estimateGas({ from: account, value: priceList });

    const gasEstimate = Math.round(gas * 1.4);

    await checkBalance(priceList, web3, account);
    return instance?.methods
      ?.mintTransfer(plaqueNames, amounts, outputData)
      .send({
        value: priceList,
        from: account,
        gas: web3.utils.toHex(gasEstimate),
        gasPrice: web3.utils.toHex(gasPrice),
      });
  } catch (e) {
    return Promise.reject(e);
  }
};

const buyPlaqueByToken = async (
  tokenId: string[],
  price: string,
  username: string,
  privateKey: string
) => {
  try {
    const web3 = await createWeb3Instance();
    const instance = await contract.getContract("", web3);
    const account = await getAccountMiddleware(false, web3, privateKey);

    const gasprice = await web3.eth.getGasPrice();
    const gasPrice = Math.round(Number(gasprice) * 1.4);

    const gas = await instance.methods
      .buyPlaque(tokenId, username)
      .estimateGas({ from: account });

    const gasEstimate = Math.round(gas * 1.4);

    await checkBalance(price, web3, account);
    return instance?.methods?.buyPlaque(tokenId, username).send({
      value: price,
      from: account,
      gas: web3.utils.toHex(gasEstimate),
      gasPrice: web3.utils.toHex(gasPrice),
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

const assignWorld = async (
  contractAddresses: string[],
  tokenIds: string[],
  plaqueTokenIds: string[],
  buildingAddresses: string[]
) => {
  try {
    const web3 = await createWeb3Instance();
    const instance = await contract.getContract("", web3);
    const account = await getAccountMiddleware(false, web3, "");

    const gasprice = await web3.eth.getGasPrice();
    const gasPrice = Math.round(Number(gasprice) * 1.4);

    buildingAddresses = buildingAddresses.map((address) => {
      return web3.utils.stringToHex(address);
    });

    const gas = await instance.methods
      .assign(
        contractAddresses,
        tokenIds,
        plaqueTokenIds,
        buildingAddresses,
        ""
      )
      .estimateGas({ from: account });

    const gasEstimate = Math.round(gas * 1.4);

    return instance?.methods
      ?.assign(
        contractAddresses,
        tokenIds,
        plaqueTokenIds,
        buildingAddresses,
        "{}"
      )
      .send({
        from: account,
        gas: web3.utils.toHex(gasEstimate),
        gasPrice: web3.utils.toHex(gasPrice),
      });
  } catch (e) {
    return Promise.reject(e);
  }
};

const unassignWorld = async (
  contractAddresses: string[],
  tokenIds: string[],
  plaqueTokenIds: number[],
  buildingAddresses: string[]
) => {
  try {
    const price = await await Request.get(URLS.metamask.plaquePrice).then(
      (res) => res.data.plaquePrice
    );

    const serverContractAddress = await contract.getWalletAddress();

    const web3 = await createWeb3Instance();
    const instance = await contract.getContract(serverContractAddress, web3);
    const account = await getAccountMiddleware(false, web3, "");

    const gasprice = await web3.eth.getGasPrice();
    const gasPrice = Math.round(Number(gasprice) * 1.4);

    buildingAddresses = buildingAddresses.map((address) => {
      return web3.utils.stringToHex(address);
    });
    const gas = await instance.methods
      .unassign(
        contractAddresses,
        tokenIds,
        plaqueTokenIds,
        buildingAddresses,
        "{}"
      )
      .estimateGas({ from: account, value: Math.floor(price / 10) });

    const gasEstimate = Math.round(gas * 1.4);

    await checkBalance(Math.floor(price / 10), web3, account);
    return instance?.methods
      ?.unassign(
        contractAddresses,
        tokenIds,
        plaqueTokenIds,
        buildingAddresses,
        "{}"
      )
      .send({
        value: Math.floor(price / 10),
        from: account,
        gas: web3.utils.toHex(gasEstimate),
        gasPrice: web3.utils.toHex(gasPrice),
      });
  } catch (e) {
    return Promise.reject(e);
  }
};

const repairPlaques = async (
  contractAddresses: string[],
  tokenIds: string[],
  plaqueTokenIds: number[]
) => {
  try {
    const price = await await Request.get(URLS.metamask.plaquePrice).then(
      (res) => res.data.plaquePrice
    );

    const serverContractAddress = await contract.getWalletAddress();

    const web3 = await createWeb3Instance();
    const instance = await contract.getContract(serverContractAddress, web3);
    const account = await getAccountMiddleware(false, web3, "");

    const gasprice = await web3.eth.getGasPrice();
    const gasPrice = Math.round(Number(gasprice) * 1.4);

    const gas = await instance.methods
      .repair(contractAddresses, tokenIds, plaqueTokenIds, "{}")
      .estimateGas({ from: account, value: Math.floor(price / 5) });

    const gasEstimate = Math.round(gas * 1.4);

    await checkBalance(Math.floor(price / 5), web3, account);
    return instance?.methods
      ?.repair(contractAddresses, tokenIds, plaqueTokenIds, "{}")
      .send({
        value: Math.floor(price / 5),
        from: account,
        gas: web3.utils.toHex(gasEstimate),
        gasPrice: web3.utils.toHex(gasPrice),
      });
  } catch (e) {
    return Promise.reject(e);
  }
};

export {
  getPlaqueDetail,
  mintAndTransferNFT,
  buyPlaqueByToken,
  getPlaquesList,
  assignWorld,
  repairPlaques,
  unassignWorld,
  sendETH,
  getGasOfSend,
};

