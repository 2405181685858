import { Request } from "api/request";
import { URLS } from "api/endpoints";
import { store } from "redux/store";
import { CART } from "constants/localstorage";
import {
  setPlaqueType,
  Types,
  setError,
  setLoading,
  cleanType,
  setSuccessPurchases,
  setPingAccount,
} from "redux/reducers/cartReducer";
import {
  buyTpaModalStatus,
  cartModalOptions,
  cartModalStatus,
  changeCartSidebarStatus,
} from "redux/reducers/modalsReducer";
import { refetchLookupData, toast } from "redux/reducers/appReducer";
import { checkUser } from "utils/userAuth";
import { applyMiddleware, checkAuthWithModal } from "./middleware";

const updateCartInfo = async (
  type: "all" | "purchase" | "unAssign",
  filter: boolean
) => {
  store.dispatch(setLoading({ type, status: true }));
  const cart = JSON.parse(localStorage.getItem(CART) as string) as Types;
  if (cart && cart.all && cart.purchase && cart.unAssign) {
    const plaqueNames = cart[type].plaques?.map((p) => {
      return p.name;
    });

    if (plaqueNames.length > 0) {
      const response = await Request.get(URLS.plaques.lookUp, {
        params: {
          name: plaqueNames,
        },
      });

      if (response) {
        let data = [];
        if (Array.isArray(response.data)) {
          data = response.data;
        } else {
          data.push(response.data);
        }

        if (filter) {
          data.map((p) => {
            const plaqueDetailIndex = cart[type].plaques.findIndex(
              (x) => x.name === p.name
            );
            cart[type].plaques[plaqueDetailIndex] = {
              ...cart[type].plaques[plaqueDetailIndex],
              ...p,
            };
            return p;
          });
          cart[type].plaques = cart[type].plaques.filter(
            (x) => x.status === "AVAILABLE"
          );
          localStorage.setItem(CART, JSON.stringify(cart));
          store.dispatch(
            setPlaqueType({
              type,
              plaques: cart[type].plaques,
            })
          );
        } else {
          data.map((p) => {
            const plaqueDetailIndex = cart[type].plaques.findIndex(
              (x) => x.name === p.name
            );
            cart[type].plaques[plaqueDetailIndex] = {
              ...cart[type].plaques[plaqueDetailIndex],
              ...p,
            };
            return p;
          });
          localStorage.setItem(CART, JSON.stringify(cart));
          store.dispatch(
            setPlaqueType({
              type,
              plaques: cart[type].plaques,
            })
          );
        }
      }
    }
  }
  store.dispatch(setLoading({ type, status: false }));
};

const initCart = async () => {
  const cart = JSON.parse(localStorage.getItem(CART) as string) as Types;
  if (cart && cart.all && cart.purchase && cart.unAssign) {
    store.dispatch(
      setPlaqueType({
        type: "all",
        plaques: cart.all.plaques,
      })
    );
    store.dispatch(
      setPlaqueType({
        type: "purchase",
        plaques: cart.purchase.plaques,
      })
    );
    store.dispatch(
      setPlaqueType({
        type: "unAssign",
        plaques: cart.unAssign.plaques,
      })
    );
    updateCartInfo("all", false);
  } else {
    localStorage.setItem(
      CART,
      JSON.stringify({
        all: {
          plaques: [],
        },
        purchase: {
          plaques: [],
        },
        unAssign: {
          plaques: [],
        },
      })
    );
  }
};

const addToCart = async (
  type: "all" | "purchase" | "unAssign",
  plaqueName: string | null
) => {
  store.dispatch(setLoading({ type, status: true }));
  await applyMiddleware(
    type,
    (plaque: any) => {
      const cart = JSON.parse(localStorage.getItem(CART) as string) as Types;
      if (cart && cart.all && cart.purchase && cart.unAssign) {
        cart[type].plaques = [...cart[type].plaques, plaque];
        localStorage.setItem(CART, JSON.stringify(cart));
        store.dispatch(
          setPlaqueType({
            type,
            plaques: cart[type].plaques,
          })
        );
        store.dispatch(refetchLookupData(true));
        store.dispatch(
          toast({ message: "Added to cart", show: true, type: "success" })
        );
      } else {
        store.dispatch(
          setError({
            type,
            error: `can't get localStorage cart`,
          })
        );
      }
    },
    plaqueName,
    false
  );
  store.dispatch(setLoading({ type, status: false }));
};

const removeFromCart = async (
  type: "all" | "purchase" | "unAssign",
  plaqueName: string
) => {
  const cart = JSON.parse(localStorage.getItem(CART) as string) as Types;
  if (cart && cart.all && cart.purchase && cart.unAssign) {
    cart[type].plaques = cart[type].plaques.filter((el) => {
      return el.name !== plaqueName;
    });

    localStorage.setItem(CART, JSON.stringify(cart));
    store.dispatch(
      setPlaqueType({
        type,
        plaques: cart[type].plaques,
      })
    );
  }
};

const cleanCart = async (type: "all" | "purchase" | "unAssign") => {
  const cart = JSON.parse(localStorage.getItem(CART) as string) as Types;
  if (cart && cart.all && cart.purchase && cart.unAssign) {
    cart[type].plaques = [];
    localStorage.setItem(CART, JSON.stringify(cart));
    store.dispatch(cleanType({ type }));
  }
};

const cleanAllCarts = async () => {
  cleanCart("all");
  cleanCart("purchase");
  cleanCart("unAssign");
};

const purchase = async () => {
  await updateCartInfo("all", false);
  const cart = JSON.parse(localStorage.getItem(CART) as string) as Types;
  if (cart && cart.all && cart.purchase && cart.unAssign) {
    if (cart.all.plaques?.length > 0) {
      if (cart.all.plaques.findIndex((x) => x.status !== "AVAILABLE") !== -1) {
        console.log("");
      } else {
        const isAuth = await checkAuthWithModal("all");
        if (isAuth) {
          cart.purchase.plaques = cart.all.plaques;
          localStorage.setItem(CART, JSON.stringify(cart));
          store.dispatch(
            setPlaqueType({
              type: "purchase",
              plaques: cart.all.plaques,
            })
          );

          store.dispatch(changeCartSidebarStatus(false));
          store.dispatch(buyTpaModalStatus(true));
        }
      }
    }
  }
};

const quickAddToCart = async (plaqueName: string | null) => {
  store.dispatch(setLoading({ type: "purchase", status: true }));
  await applyMiddleware(
    "all",
    (plaque: any) => {
      const cart = JSON.parse(localStorage.getItem(CART) as string) as Types;
      if (cart && cart.all && cart.purchase && cart.unAssign) {
        cart.all.plaques = [...cart.all.plaques, plaque];
        cart.purchase.plaques = cart.all.plaques;
        localStorage.setItem(CART, JSON.stringify(cart));
        store.dispatch(
          setPlaqueType({
            type: "all",
            plaques: cart.all.plaques,
          })
        );
        store.dispatch(refetchLookupData(true));
        store.dispatch(changeCartSidebarStatus(true));
      } else {
        store.dispatch(
          setError({
            type: "all",
            error: `can't get localStorage cart`,
          })
        );
      }
    },
    plaqueName
  );
  store.dispatch(setLoading({ type: "purchase", status: false }));
};

const quickPurchase = async (plaqueName: string | null) => {
  store.dispatch(setLoading({ type: "purchase", status: true }));
  let givenPlaque = null;
  await applyMiddleware(
    "all",
    (plaque: any) => {
      const cart = JSON.parse(localStorage.getItem(CART) as string) as Types;
      if (cart && cart.all && cart.purchase && cart.unAssign) {
        cart.all.plaques = [...cart.all.plaques, plaque];
        cart.purchase.plaques = cart.all.plaques;
        localStorage.setItem(CART, JSON.stringify(cart));
        store.dispatch(
          setPlaqueType({
            type: "all",
            plaques: cart.all.plaques,
          })
        );
        givenPlaque = plaque;
      } else {
        store.dispatch(
          setError({
            type: "all",
            error: `can't get localStorage cart`,
          })
        );
      }
    },
    plaqueName,
    true,
    false
  );
  store.dispatch(setLoading({ type: "purchase", status: false }));
  if (givenPlaque) {
    const cart = JSON.parse(localStorage.getItem(CART) as string) as Types;
    if (cart && cart.all && cart.purchase && cart.unAssign) {
      const isUserAuth = await checkUser(false);
      if (isUserAuth) {
        store.dispatch(refetchLookupData(true));
        cart.purchase.plaques = [givenPlaque];
        localStorage.setItem(CART, JSON.stringify(cart));
        store.dispatch(
          setPlaqueType({
            type: "purchase",
            plaques: [givenPlaque],
          })
        );
        store.dispatch(buyTpaModalStatus(true));
        store.dispatch(cartModalOptions(true));
      }
    }
  }
};

const SuccessPurchase = async () => {
  const cart = JSON.parse(localStorage.getItem(CART) as string) as Types;
  if (cart && cart.all && cart.purchase && cart.unAssign) {
    const plaqueNames = cart.purchase.plaques.map((p) => {
      return p.name;
    });

    store.dispatch(setSuccessPurchases(cart.purchase.plaques));
    store.dispatch(setPingAccount(""));

    cart.purchase.plaques = [];

    plaqueNames.map((name) => {
      cart.all.plaques = cart.all.plaques.filter((el) => {
        return el.status === name;
      });
      return name;
    });

    localStorage.setItem(CART, JSON.stringify(cart));
    store.dispatch(
      setPlaqueType({
        type: "all",
        plaques: cart.all.plaques,
      })
    );
    store.dispatch(
      setPlaqueType({
        type: "purchase",
        plaques: [],
      })
    );
  }
};

const SuccessUnAssign = async () => {};

export {
  initCart,
  updateCartInfo,
  addToCart,
  removeFromCart,
  cleanCart,
  cleanAllCarts,
  purchase,
  quickPurchase,
  SuccessPurchase,
  SuccessUnAssign,
  quickAddToCart,
};

