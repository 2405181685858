import { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import ShareLink from "./components/shareLink";

const ShareTPAModals: React.FC = () => {
  const [step, setStep] = useState(0);
  const selected = useAppSelector((state) => state.modals.getTPALink.data);

  useEffect(() => {
    setStep(0);
  }, []);

  return (
    <div className="p-9">
      <div className="-mt-14 font-semibold text-sm flex flex-row items-center justify-start">
        <span className="mr-2">Share Teleport Plaque Address</span>
      </div>
      <ShareLink />
      <style>{`
        .hint {
        }
        .arrow-down {
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          right: 15px;
          bottom: -8px;
          border-top: 8px solid white;
        }
        .hint .tooltiptext {
          visibility: hidden;
          background-color: white;
          color: black;

          /* Position the tooltip text - see examples below! */
          position: absolute;
          z-index: 1;
        }
        .hint:hover .tooltiptext {
          visibility: visible;
        }
      `}</style>
    </div>
  );
};

export default ShareTPAModals;
