import { getCookie } from "cookies-next";
import { META_PROVIDER_NAME } from "constants/cookie";
import { Request } from "api/request";
import { URLS } from "api/endpoints";
import { ProviderNames } from "constants/configs";
import createWeb3Instance from "./web3";

/* eslint-disable */
function confirmEtherTransaction(
  txHash: string,
  confirmations = 1,
  callback: any,
  errCallback: any
) {
  setTimeout(async () => {
    // Get current number of confirmations and compare it with sought-for value
    const trxConfirmations = await getConfirmations(txHash, errCallback);

    console.log(
      `Transaction with hash ${txHash} has ${trxConfirmations} confirmation(s)`
    );

    if (trxConfirmations >= confirmations) {
      // Handle confirmation event according to your business logic

      callback();
      console.log(
        `Transaction with hash ${txHash}  has been successfully confirmed`
      );

      return;
    }
    // Recursive call
    return confirmEtherTransaction(
      txHash,
      confirmations,
      callback,
      errCallback
    );
  }, 10 * 1000);
}
/* eslint-enable */

async function getConfirmations(txHash: string, errCallback: any) {
  try {
    let providerName = null as any;
    const providerNameCookie = getCookie(META_PROVIDER_NAME) as any;
    if (
      providerNameCookie !== null &&
      providerNameCookie !== "null" &&
      providerNameCookie !== undefined &&
      providerNameCookie
    ) {
      providerName = providerNameCookie;
    }

    if (
      (typeof window !== "undefined" &&
        typeof window["ethereum" as keyof Window] !== "undefined") ||
      providerName === ProviderNames.StageWallet
    ) {
      const web3 = await createWeb3Instance();
      // Get transaction details
      const trx = await web3.eth.getTransaction(txHash);

      // Get current block number
      const currentBlock = await web3.eth.getBlockNumber();

      // When transaction is unconfirmed, its block number is null.
      // In this case we return 0 as number of confirmations
      if (trx.blockNumber) {
        return trx.blockNumber === null ? 0 : currentBlock - trx.blockNumber;
      }
      return 0;
    }
    return 0;
  } catch (error) {
    errCallback(error);
    return 0;
  }
}

const getBalance = async (walletAddress: string) => {
  const web3 = await createWeb3Instance();
  let weiBalance = "";
  let etherBalance = 0;
  try {
    weiBalance = await web3.eth.getBalance(walletAddress);
    etherBalance = Number(web3.utils.fromWei(weiBalance, "ether"));
  } catch (error) {
    console.error(error);
  }
  return { etherBalance, weiBalance };
};

const checkBalance = async (priceList: any, web3: any, account: string) => {
  const balance = await web3.eth.getBalance(account);
  const gasprice = await web3.eth.getGasPrice();
  const gasPrice = Math.round(Number(gasprice) * 1.4);
  if (Number(balance) < Number(gasPrice) + Number(priceList)) {
    throw new Error("Balance is not enough");
  }
};

const setStageWallet = async (web3: any, privateKey: string) => {
  if (privateKey) {
    const account = web3.eth.accounts.privateKeyToAccount(`${privateKey}`);
    account.address = web3.utils.toChecksumAddress(account.address as string);
    account.address = account.address.toLowerCase().toString();
    web3.eth.accounts.wallet.add(account);
    web3.eth.defaultAccount = account.address;
    return account;
  }
  const response = await Request.get(URLS.metamask.stageWalletPrivateKey);
  if (response) {
    const privateKeyApi = response?.data;
    const account = web3.eth.accounts.privateKeyToAccount(`${privateKeyApi}`);
    account.address = web3.utils.toChecksumAddress(account.address as string);
    account.address = account.address.toLowerCase().toString();
    web3.eth.accounts.wallet.add(account);
    web3.eth.defaultAccount = account.address;
    return account;
  }
  return null;
};

const getAccountMiddleware = async (
  requestWallet: boolean,
  web3: any,
  privateKey: string
) => {
  let account = null;
  const providerNameCookie = getCookie(META_PROVIDER_NAME) as any;
  if (
    providerNameCookie !== null &&
    providerNameCookie !== "null" &&
    providerNameCookie !== undefined &&
    providerNameCookie
  ) {
    if (providerNameCookie === ProviderNames.StageWallet) {
      const acc = await setStageWallet(web3, privateKey);
      account = acc?.address;
    } else {
      const [firstAccount] = await web3.eth.getAccounts();
      account = firstAccount;
      if (
        typeof window !== "undefined" &&
        typeof window["ethereum" as keyof Window] !== "undefined" &&
        requestWallet
      ) {
        const win = window as any;
        await win.ethereum.request({ method: "eth_requestAccounts" });
      }
    }
  }
  return account;
};

const blockchainErrorHandler = (e: any) => {
  let errorMessage = e?.message as string;
  if (errorMessage) {
    const splitted = errorMessage.split(".");
    /*eslint-disable */
    errorMessage = splitted[0];
    errorMessage = errorMessage.replace("MetaMask Tx Signature:", "");

    if (errorMessage?.includes("code=INVALID_ARGUMENT")) {
      errorMessage = "Invalid arguments found , Try again";
    } else if (errorMessage?.includes("code=")) {
      errorMessage = "An unexpected error occurred, Try again";
    } else if (errorMessage?.includes("reverted")) {
      errorMessage = "Transaction Has been reverted, Try again";
    } else if (errorMessage?.includes("blockHash")) {
      errorMessage = "An unexpected error occurred, Try again";
    } else if (errorMessage?.includes("insufficient funds")) {
      errorMessage = "Balance is not enough";
    }
    /* eslint-enable */
    return errorMessage as string;
  }

  return "An unexpected error occurred, Try again";
};

const walletAddressValidation = async (address: string) => {
  const web3 = await createWeb3Instance();
  if (web3.utils.isAddress(address)) return address;

  try {
    const ens = await web3.eth.ens.getAddress(address);
    return ens;
  } catch (e) {
    console.error(e);
  }
  return "";
};

export {
  confirmEtherTransaction,
  checkBalance,
  blockchainErrorHandler,
  getAccountMiddleware,
  getBalance,
  walletAddressValidation,
};

