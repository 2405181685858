import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPlaques } from "entities/plaque.entity";

export interface Types {
  all: {
    loading: boolean;
    error: string | null;
    plaques: IPlaques[];
    plaqueName: string;
  };
  purchase: {
    loading: boolean;
    error: string | null;
    paymentMethod: "debit" | "crypto";
    plaques: IPlaques[];
    pingAccount: string;
    pingPlaque: string;
    transactionId: string;
  };
  unAssign: {
    loading: boolean;
    error: string | null;
    plaques: IPlaques[];
  };
  successPurchases: {
    plaques: IPlaques[];
  };
  sendETH: {
    plaqueName?: string;
    amount?: number;
    ethBalance?: number;
    walletAddress?: string;
    transactionHash?: string;
    sendMethod?: "TPA" | "walletAddress";
  };
}

const initialState: Types = {
  all: {
    plaques: [],
    error: null,
    loading: false,
    plaqueName: "",
  },
  purchase: {
    paymentMethod: "debit",
    plaques: [],
    error: null,
    loading: false,
    pingAccount: process.env.REACT_APP_PING_ACCOUNT || "",
    pingPlaque: "",
    transactionId: "",
  },
  unAssign: {
    plaques: [],
    error: null,
    loading: false,
  },
  successPurchases: {
    plaques: [],
  },
  sendETH: {
    sendMethod: "TPA",
  },
};

export const cartReducer = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setLoading: (
      state,
      action: PayloadAction<{
        type: "all" | "purchase" | "unAssign";
        status: boolean;
      }>
    ) => {
      if (action.payload.type === "purchase") {
        state.purchase.loading = action.payload.status;
      } else if (action.payload.type === "unAssign") {
        state.unAssign.loading = action.payload.status;
      } else {
        state.all.loading = action.payload.status;
      }
    },
    setError: (
      state,
      action: PayloadAction<{
        type: "all" | "purchase" | "unAssign";
        error: string | null;
      }>
    ) => {
      if (action.payload.type === "purchase") {
        state.purchase.error = action.payload.error;
      } else if (action.payload.type === "unAssign") {
        state.unAssign.error = action.payload.error;
      } else {
        state.all.error = action.payload.error;
      }
    },
    setPlaqueName: (state, action: PayloadAction<string>) => {
      state.all.plaqueName = action.payload;
    },
    setErrorAll: (state, action: PayloadAction<string | null>) => {
      state.all.error = action.payload;
      state.purchase.error = action.payload;
      state.unAssign.error = action.payload;
    },
    setPurchasePaymentMethod: (
      state,
      action: PayloadAction<"debit" | "crypto">
    ) => {
      state.purchase.paymentMethod = action.payload;
    },
    setSuccessPurchases: (state, action: PayloadAction<IPlaques[]>) => {
      state.successPurchases.plaques = action.payload;
    },
    setPingAccount: (state, action: PayloadAction<string>) => {
      state.purchase.pingAccount = action.payload;
    },
    setPingPlaque: (state, action: PayloadAction<string>) => {
      state.purchase.pingPlaque = action.payload;
    },
    setTransactionId: (state, action: PayloadAction<string>) => {
      state.purchase.transactionId = action.payload;
    },
    setPlaqueType: (
      state,
      action: PayloadAction<{
        type: "all" | "purchase" | "unAssign";
        plaques: IPlaques[];
      }>
    ) => {
      if (action.payload.type === "purchase") {
        state.purchase.plaques = action.payload.plaques;
      } else if (action.payload.type === "unAssign") {
        state.unAssign.plaques = action.payload.plaques;
      } else {
        state.all.plaques = action.payload.plaques;
      }
    },
    setSendEth: (state, action: PayloadAction<Types["sendETH"]>) => {
      return { ...state, sendETH: { ...state.sendETH, ...action.payload } };
    },
    cleanSendEth: (state) => {
      state.sendETH = { sendMethod: "TPA" };
    },
    cleanType: (
      state,
      action: PayloadAction<{ type: "all" | "purchase" | "unAssign" }>
    ) => {
      if (action.payload.type === "purchase") {
        state.purchase.paymentMethod = "crypto";
        state.purchase.loading = false;
        state.purchase.error = null;
        state.purchase.plaques = [];
        state.purchase.pingAccount = "";
        state.purchase.pingPlaque = "";
        state.purchase.transactionId = "";
      } else if (action.payload.type === "unAssign") {
        state.unAssign.plaques = [];
        state.purchase.loading = false;
        state.purchase.error = null;
      } else {
        state.all.plaques = [];
        state.purchase.loading = false;
        state.purchase.error = null;
        state.purchase.pingAccount = "";
        state.purchase.pingPlaque = "";
        state.purchase.transactionId = "";
      }
    },
  },
});

export const {
  cleanType,
  setPurchasePaymentMethod,
  setPlaqueType,
  setLoading,
  setError,
  setErrorAll,
  setSuccessPurchases,
  setPingAccount,
  setPingPlaque,
  setTransactionId,
  setSendEth,
  cleanSendEth,
  setPlaqueName,
} = cartReducer.actions;

export default cartReducer.reducer;
