import { PlaqueImage } from 'components/plaque-image';
import { FC } from 'react';
import { DeleteIcon } from 'utils/iconPack';

interface Props {
  imgUrl: string;
  name: string;
  price: string;
  removeOnly?: boolean;
  onDeleteIconClick: () => void;
}

export const CartPlaque: FC<Props> = ({
  imgUrl,
  name,
  price,
  removeOnly,
  onDeleteIconClick,
}) => {
  return (
    <div className="flex justify-between items-center hover:bg-[#FFFFFF29] p-2.5 px-8 group">
      <div className="flex items-center">
        <div className="w-[80px] h-[80px] mr-4">
          <PlaqueImage name={name} src={imgUrl} />
        </div>
        <div className="flex flex-col">
          <span className="text-xs">TPA</span>
          <span className="font-saira font-semibold text-xl">
            {name.toUpperCase()}
          </span>
        </div>
      </div>
      <div>
        {!removeOnly && (
          <span className="font-saira font-medium group-hover:hidden">
            ${Number(price).toFixed(2)}
          </span>
        )}
        <button
          onClick={() => onDeleteIconClick()}
          className={`${!removeOnly ? 'hidden' : 'block'} group-hover:block`}>
          <DeleteIcon />
        </button>
      </div>
    </div>
  );
};
