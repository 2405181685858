// import { PlaqueImage } from 'src/components/plaque-image';
import { useAppSelector } from "redux/hooks";
import { FC } from "react";

interface Props {
  imgUrl: string;
  name: string;
  usdPrice: number;
  ethPrice: number;
}

export const PlaqueItem: FC<Props> = ({ imgUrl, name, usdPrice, ethPrice }) => {
  const { paymentMethod } = useAppSelector((state) => state.cart.purchase);

  return (
    <div className="flex items-center w-full">
      <div className="flex items-center w-full">
        <img className=" w-[72px] h-[72px] mr-[16px]" alt={name} src={imgUrl} />
        <div className="w-full flex flex-col lg:flex-row justify-between">
          <div className="flex flex-col items-start">
            <span className="text-xs mb-2">TPA</span>
            <span className="font-saira font-semibold text-xl">
              {name?.toUpperCase()}
            </span>
          </div>
          <div className="flex flex-col mt-1 lg:mt-0 lg:items-end">
            <span className="font-saira font-medium text-[20px]">
              ${usdPrice.toFixed(2)}
            </span>
            {paymentMethod === "crypto" && (
              <span className="text-[12px] font-inter font-medium text-[#A3A3A3]">
                (= {ethPrice?.toFixed(6)} ETH)
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
