import { StageIcon } from "assets/icons/stageIcon";
import { ProviderNames } from "constants/configs";

interface LoginProps {
  setForm: any;
  setProviderName: (name: ProviderNames) => void;
}

const Login: React.FC<LoginProps> = ({
  setForm,
  setProviderName,
}: LoginProps) => {
  return (
    <>
      <div className="flex flex-col flex-1 justify-center items-center py-[80px]">
        <p className="mb-5 text-2xl md:text-4xl font-bold text-center mx-2 text-white">
          Login
        </p>
        <p className="mb-5 text-base md:text-xl font-normal mx-7 text-center md:whitespace-pre leading-7 text-white">
          continue with your wallet
        </p>
        <button
          onClick={() => {
            setForm("email");
            setProviderName(ProviderNames.StageWallet);
          }}
          className="pointer rounded-xl py-4 text-white bg-colorful h-[64px] text-lg font-semibold flex justify-center items-center w-96 max-w-full xl:w-[520px] lg:w-[300px] sm:w-[3/4]"
        >
          <StageIcon />
        </button>

        <button
          onClick={() => setForm("connect")}
          className="underline text-white text-base font-normal mt-4"
        >
          Show More Options
        </button>
      </div>
      <style>
        {`
        .submit-btn {
          background: linear-gradient(
            107.15deg,
            #da39e0 0%,
            #6e6aff 53.65%,
            #320d6c 100%
          );
          margin-right: 8px;
          flex: 1;
        }

        `}
      </style>
    </>
  );
};

export default Login;
