import { useEffect, useState } from "react";
import { confirmEtherTransaction } from "blockchain/helper";
// import { SuccessPurchase } from 'cart/functions';
import { useAppSelector } from "redux/hooks";
import { SuccessPurchase } from "cart/functions";

interface ProcessingPurchaseProps {
  setStep: (n: number) => void;
}

const ProcessingPurchase: React.FC<ProcessingPurchaseProps> = ({ setStep }) => {
  const { transactionId } = useAppSelector((state) => state.cart.purchase);

  const [loading] = useState(true);

  useEffect(() => {
    if (transactionId !== "") {
      if (transactionId != null) {
        confirmEtherTransaction(
          transactionId,
          1,
          () => {
            SuccessPurchase();
            setStep(5);
          },
          () => {
            // console.log(err);
          }
        );
      }
    }
  }, [transactionId]);

  return (
    <div className="mt-[24px] pb-8">
      <div className="flex flex-col items-start justify-center px-5 lg:px-8 ">
        <div className="text-2xl font-semibold mb-[56px] text-center relative font-saira">
          Minting in process...
        </div>
        <p className="mb-[32px] text-base text-white">
          Minting is in process, it will be confirmed on the blockchain shortly.
        </p>
        {transactionId && (
          <>
            <p className="mb-1 break-all">{"Transaction hash id"}:</p>
            <p className="mb-4 break-all">{transactionId}</p>
          </>
        )}

        <div className="place-self-center">
          {loading && <div className="circle-loader mx-auto mt-[28px]" />}
        </div>
      </div>
      <style>
        {`
        .circle-loader {
          border: 4px solid #f3f3f3;
          border-radius: 50%;
          border-top: 4px solid #F12711;
          width: 27px;
          height: 27px;
          -webkit-animation: spin 2s linear infinite; /* Safari */
          animation: spin 2s linear infinite;
        }
        
        /* Safari */
        @-webkit-keyframes spin {
          0% {
            -webkit-transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
          }
        }
        
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }        
        `}
      </style>
    </div>
  );
};

export { ProcessingPurchase };
