import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { getRequestedProvider } from "blockchain/auth";
import { loginUser } from "utils/userAuth";
import { Request } from "api/request";
import { URLS } from "api/endpoints";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { ProviderNames } from "constants/configs";
import { Verification } from "components/form/verificationInput";
import { setTempToken } from "redux/reducers/userReducer";

interface CreateFormData {
  code: string;
}

interface CreateProps {
  setForm: any;
  providerName: ProviderNames;
}

const RESEND_CODE_TIME_LIMIT = 60 as any;

const Code: React.FC<CreateProps> = ({
  setForm,
  providerName,
}: CreateProps) => {
  const dispatch = useAppDispatch();

  const tempToken = useAppSelector((state) => state.user.tempToken);
  const email = useAppSelector((state) => state.user.email);

  const [loading, setLoading] = useState(false);

  // const [email, setEmail] = useState('');

  const [resendLoading, setResendLoading] = useState(false);

  const [countDown, setCountDown] = useState(RESEND_CODE_TIME_LIMIT);

  const { control, reset, handleSubmit, setError, setValue } =
    useForm<CreateFormData>();

  useEffect(() => {
    const interval = setTimeout(() => {
      if (countDown) setCountDown(countDown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDown]);

  async function resendCode() {
    reset();
    setValue("code", "");
    setResendLoading(true);
    if (providerName === ProviderNames.StageWallet) {
      const emailVerify = await Request.post(URLS.auth.stageWalletRegister, {
        email,
      });
      if (emailVerify) {
        setCountDown(RESEND_CODE_TIME_LIMIT);
      }
    } else {
      const emailVerify = await Request.get(URLS.auth.emailVerification, {
        params: {
          email,
        },
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      });
      if (emailVerify) {
        setCountDown(RESEND_CODE_TIME_LIMIT);
      }
    }
    setResendLoading(false);
  }

  const onSubmit = async (data: CreateFormData) => {
    setLoading(true);
    const token = tempToken as string;
    const emailVerifyCode = await Request.get(URLS.auth.emailVerificationCode, {
      params: {
        code: data.code,
        email,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).catch((err: any) => {
      setError("code", { message: err?.data?.message, type: "required" });
    });
    if (emailVerifyCode) {
      if (emailVerifyCode.data.user.username) {
        const provider = await getRequestedProvider(providerName);
        loginUser(
          emailVerifyCode.data.token,
          emailVerifyCode.data.user,
          providerName,
          provider
        );
        setForm("complete");
      } else {
        dispatch(setTempToken(emailVerifyCode.data?.token));
        if (providerName === ProviderNames.StageWallet) {
          setForm("account");
        } else {
          setForm("create");
        }

        // const provider = await getRequestedProvider(providerName);
        // loginUser(
        //   emailVerifyCode.data.token,
        //   emailVerifyCode.data.user,
        //   providerName,
        //   provider,
        // );
      }
    }
    setLoading(false);
  };

  /* eslint-disable */
  return (
    <>
      <div className="flex flex-col flex-1 justify-center items-center py-[64px]">
        <span className="text-2xl font-semibold text-center mt-[48px] mb-[8px]">
          Enter verification code:
        </span>
        <span className="text-xs md:text-lg text-[#E5E5E5] font-normal text-center mb-[32px]">
          Enter the code that was sent to your email inbox.
        </span>
        <form
          className="w-5/6 md:w-[384px] mb-[28px]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full flex flex-col items-center mb-3">
            <Verification
              control={control}
              name="code"
              rules={{
                required: "Please the validation code",
              }}
            />
          </div>
          <div className="text-center flex justify-center items-center w-full mt-[16px]">
            {countDown ? (
              <span className="text-lg">
                (0
                {countDown === RESEND_CODE_TIME_LIMIT
                  ? Math.floor(RESEND_CODE_TIME_LIMIT / 60)
                  : 0}
                :
                {RESEND_CODE_TIME_LIMIT - countDown === 0
                  ? "00"
                  : RESEND_CODE_TIME_LIMIT - countDown >
                    RESEND_CODE_TIME_LIMIT - 10
                  ? `0${countDown}`
                  : countDown}
                )
              </span>
            ) : (
              <button onClick={resendCode} disabled={resendLoading}>
                <span className="font-normal underline underline-offset-2">
                  Resend code
                </span>
              </button>
            )}
          </div>
          <button
            type="submit"
            disabled={loading}
            className="flex mt-[32px] mb-[40px] bg-colorful items-center justify-center flex-row h-[48px] w-full md:w-[384px] rounded-2xl"
          >
            {loading ? (
              <span
                className={`w-full h-full flex items-center justify-center relative ${
                  loading ? "block" : "hidden"
                }`}
              >
                Loading...
              </span>
            ) : (
              <span className="text-white text-xl font-semibold ">
                {providerName === ProviderNames.StageWallet
                  ? "continue"
                  : "register"}
              </span>
            )}
          </button>
          <button
            className="m-auto block text-lg font-normal mt-3 underline"
            onClick={() => {
              if (providerName === ProviderNames.StageWallet) setForm("email");
              else {
                setForm("create");
              }
            }}
          >
            Change Email
          </button>
        </form>
      </div>
      <style>
        {`
        .metamask-icon {
          width: 150px;
          height: 29.48px; 
        }
        .confirm-btn {
          background: linear-gradient(
            107.15deg,
            #da39e0 0%,
            #6e6aff 53.65%,
            #320d6c 100%
          );
        }
        `}
      </style>
    </>
  );
  /* eslint-enable */
};

export default Code;
