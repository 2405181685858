// import dynamic from 'next/dynamic';
import { logoutUser, checkUser } from "utils/userAuth";
import { getCookie } from "cookies-next";
import { ProviderNames } from "constants/configs";
import { META_PROVIDER_NAME } from "constants/cookie";
import MetaMaskOnboarding from "@metamask/onboarding";
import { changeNetworkStatus } from "redux/reducers/appReducer";
import { store } from "redux/store";

// const Web3 = dynamic(() => import('web3').then((mod) => mod.default), {
//   ssr: false,
// });

const handleSignMessage = async (
  publicAddress: string,
  nonce: string,
  provider: any
): Promise<{ publicAddress: string; signature: string }> => {
  const Web3 = (await import("web3")).default;
  const web3 = new Web3(provider);
  /* eslint-disable */
  return new Promise((resolve, reject) =>
    web3?.eth.personal.sign(
      web3?.utils.utf8ToHex(`Nonce: ${nonce}`),
      publicAddress,
      "",
      (err: any, signature: any) => {
        if (err) return reject(err);
        return resolve({ publicAddress, signature });
      }
    )
  );
  /* eslint-enable */
};

async function getRequestedProvider(
  providerName:
    | ProviderNames.MetaMask
    | ProviderNames.CoinBase
    | ProviderNames.StageWallet
    | null
) {
  const win = window as any;
  let provider = null as any;
  if (win.ethereum) {
    const { ethereum } = win;
    if (ethereum?.providers && providerName !== ProviderNames.StageWallet) {
      const iterator = ethereum.providerMap.entries();
      ethereum?.providers.map((item: any) => {
        const pv = iterator.next().value;
        if (providerName === null) {
          provider = ethereum.selectedProvider;
        } else if (providerName === pv[0]) {
          provider = pv[1] as any;
        }
        return item;
      });
      if (providerName !== null) {
        await ethereum?.setSelectedProvider(provider);
      }
    } else if (providerName === ProviderNames.StageWallet) {
      const Web3 = (await import("web3")).default;
      const givenProvider = new Web3.providers.HttpProvider(
        process.env.REACT_APP_PROVIDER as string
      );
      provider = givenProvider;
    } else {
      provider = ethereum;
    }
  }
  return provider;
}

async function checkExtensionsIsAvailable(
  providerName: ProviderNames.MetaMask | ProviderNames.CoinBase,
  isMobileScreen: boolean
) {
  const win = window as any;
  let providerExist = null as any;
  if (win.ethereum) {
    const { ethereum } = win;
    if (ethereum?.providers) {
      const iterator = ethereum.providerMap.entries();
      ethereum?.providers.map((item: any) => {
        const pv = iterator.next().value;
        if (providerName === pv[0]) {
          providerExist = pv[1] as any;
        }
        return item;
      });
    }
  } else if (providerName === ProviderNames.MetaMask) {
    if (isMobileScreen) {
      window.open("https://metamask.io/download.html", "_blank");
    } else {
      const onboarding = new MetaMaskOnboarding();
      onboarding.startOnboarding();
    }
    return null;
  } else if (providerName === ProviderNames.CoinBase) {
    window.open("https://www.coinbase.com/wallet/downloads", "_blank");
    return null;
  }

  if (providerExist === null && win?.ethereum?.providers) {
    if (providerName === ProviderNames.CoinBase) {
      window.open("https://www.coinbase.com/wallet/downloads", "_blank");
    } else if (providerName === ProviderNames.MetaMask) {
      if (isMobileScreen) {
        window.open("https://metamask.io/download.html", "_blank");
      } else {
        const onboarding = new MetaMaskOnboarding();
        onboarding.startOnboarding();
      }
    }
  } else if (win.ethereum && providerExist === null) {
    if (!win.ethereum?.isMetaMask && providerName === ProviderNames.MetaMask) {
      if (isMobileScreen) {
        window.open("https://metamask.io/download.html", "_blank");
      } else {
        const onboarding = new MetaMaskOnboarding();
        onboarding.startOnboarding();
      }
    } else if (
      !win.ethereum?.isCoinbaseWallet &&
      providerName === ProviderNames.CoinBase
    ) {
      window.open("https://www.coinbase.com/wallet/downloads", "_blank");
    } else {
      providerExist = win.ethereum;
    }
  } else if (!win.ethereum && isMobileScreen && providerExist === null) {
    window.open("https://metamask.io/download.html", "_blank");
  } else if (providerExist === null) {
    const onboarding = new MetaMaskOnboarding();
    onboarding.startOnboarding();
  }
  return providerExist;
}

async function checkProviderAlreadyHasAccount(provider: any) {
  const Web3 = (await import("web3")).default;
  const web3 = new Web3(provider);
  // const network = await web3.eth.net.getNetworkType();
  const accounts = await web3.eth.getAccounts();
  // check correct network
  // if (network !== process.env.NETWORK) {
  //   store.dispatch(
  //     changeNetworkStatus({
  //       network: network as string,
  //       status: true,
  //     }),
  //   );
  //   return false;
  // }
  // check has account
  if (accounts.length === 0) {
    return false;
  }
  return accounts[0];
}

async function checkWalletConditionsInitial() {
  const userAuth = await checkUser(false);
  if (userAuth) {
    let currentProvider = null as any;
    const providerNameCookie = getCookie(META_PROVIDER_NAME) as any;
    if (
      providerNameCookie !== null &&
      providerNameCookie !== "null" &&
      providerNameCookie !== undefined &&
      providerNameCookie
    ) {
      if (providerNameCookie !== ProviderNames.StageWallet) {
        currentProvider = await getRequestedProvider(providerNameCookie);
      }
    }

    if (currentProvider) {
      const Web3 = (await import("web3")).default;
      const web3 = new Web3(currentProvider);
      const [account] = await web3.eth.getAccounts();
      const user = JSON.parse(localStorage.getItem("user") as string);
      if (!account) {
        logoutUser();
        setTimeout(() => {
          window.location.replace(window.location.origin);
        }, 100);
      } else if (
        account?.toLowerCase() !== user?.walletAddress?.toLowerCase()
      ) {
        logoutUser();
        setTimeout(() => {
          window.location.replace(window.location.origin);
        }, 100);
      }
    }
  }
}

async function checkNetworkType(currentProvider: any) {
  const Web3 = (await import("web3")).default;
  const web3 = new Web3(currentProvider);
  web3.eth.net.getNetworkType((err, networkName) => {
    if (err) {
      store.dispatch(
        changeNetworkStatus({
          network: "wrong network",
          status: true,
        })
      );
    } else if (networkName === process.env.NETWORK) {
      store.dispatch(
        changeNetworkStatus({
          network: process.env.NETWORK as string,
          status: false,
        })
      );
    } else {
      store.dispatch(
        changeNetworkStatus({
          network: networkName,
          status: true,
        })
      );
    }
    // console.log(err, networkName);
  });
}

async function subscribeToWeb3Events(provider: any, initial: boolean) {
  const userAuth = await checkUser(false);
  const providerNameCookie = getCookie(META_PROVIDER_NAME) as any;
  if (userAuth) {
    let currentProvider = null as any;
    if (initial) {
      if (
        providerNameCookie !== null &&
        providerNameCookie !== "null" &&
        providerNameCookie !== undefined &&
        providerNameCookie
      ) {
        if (providerNameCookie !== ProviderNames.StageWallet) {
          currentProvider = await getRequestedProvider(providerNameCookie);
        }
      }
    } else {
      currentProvider = provider;
    }

    if (currentProvider) {
      if (providerNameCookie === ProviderNames.StageWallet) {
        store.dispatch(
          changeNetworkStatus({
            network: process.env.NETWORK as string,
            status: false,
          })
        );
        return;
      }
      checkNetworkType(currentProvider);
      currentProvider.on("accountsChanged", (accounts: any) => {
        const user = JSON.parse(localStorage.getItem("user") as string);
        if (user?.walletAddress) {
          if (accounts.length > 0) {
            if (accounts[0] !== user.walletAddress) {
              logoutUser();
              setTimeout(() => {
                window.location.replace(window.location.origin);
              }, 100);
            }
          } else if (accounts.length === 0) {
            logoutUser();
            setTimeout(() => {
              window.location.replace(window.location.origin);
            }, 100);
          }
        }
      });

      currentProvider.on("chainChanged", async () => {
        checkNetworkType(currentProvider);
      });
    }
  }
}

async function unSubscribeToWeb3Events(provider: any) {
  if (provider) {
    provider?.removeListener("accountsChanged", () => {
      // console.log('accountsChanged event listener removed');
    });

    provider?.removeListener("networkChanged", () => {
      // console.log('networkChanged event listener removed');
    });
  }
}

async function getWeb3(provider: any) {
  const Web3 = (await import("web3")).default;
  const web3 = new Web3(provider);
  return web3;
}

export {
  handleSignMessage,
  getRequestedProvider,
  checkProviderAlreadyHasAccount,
  getWeb3,
  checkExtensionsIsAvailable,
  subscribeToWeb3Events,
  unSubscribeToWeb3Events,
  checkWalletConditionsInitial,
};

