import * as React from "react";
import { SVGProps } from "react";

const SVGComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_10133_5421)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#627EEA"
      />
      <path
        d="M12.375 3V9.6525L17.9978 12.165L12.375 3Z"
        fill="white"
        fill-opacity="0.602"
      />
      <path d="M12.3735 3L6.75 12.165L12.3735 9.6525V3Z" fill="white" />
      <path
        d="M12.375 16.4749V20.9952L18.0015 13.2109L12.375 16.4749Z"
        fill="white"
        fill-opacity="0.602"
      />
      <path
        d="M12.3735 20.9952V16.4742L6.75 13.2109L12.3735 20.9952Z"
        fill="white"
      />
      <path
        d="M12.375 15.4281L17.9978 12.1633L12.375 9.65234V15.4281Z"
        fill="white"
        fill-opacity="0.2"
      />
      <path
        d="M6.75 12.1633L12.3735 15.4281V9.65234L6.75 12.1633Z"
        fill="white"
        fill-opacity="0.602"
      />
    </g>
    <defs>
      <clipPath id="clip0_10133_5421">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
