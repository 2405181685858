import { useEffect, useState } from "react";
import { useAppDispatch } from "redux/hooks";
import { connectWalletModalStatus } from "redux/reducers/modalsReducer";
import { ProviderNames } from "constants/configs";
import Code from "./code";
import Create from "./create";
import Connect from "./connect";
import Login from "./login";
import Complete from "./complete";
import Email from "./email";
import Account from "./account";

const AuthModals: React.FC = () => {
  const dispatch = useAppDispatch();

  const [form, setForm] = useState("login");
  const [providerName, setProviderName] = useState<
    ProviderNames.MetaMask | ProviderNames.CoinBase | ProviderNames.StageWallet
  >(ProviderNames.StageWallet);

  const handleWeb3 = async () => {
    const win = window as any;
    const Web3 = (await import("web3"))?.default;
    const web3 = new Web3(win?.ethereum);
    web3.givenProvider?.on("accountsChanged", (addr: string[]) => {
      if (addr.length === 0) {
        setForm("connect");
      }
    });
  };

  useEffect(() => {
    if (!window) return;
    handleWeb3();
  }, []);

  return (
    <>
      {form === "login" && (
        <Login
          setForm={(page: string) => setForm(page)}
          setProviderName={(name) => setProviderName(name)}
        />
      )}
      {form === "email" && <Email setForm={(page: string) => setForm(page)} />}
      {form === "connect" && (
        <Connect
          setForm={(page: string) => setForm(page)}
          setProviderName={(name) => setProviderName(name)}
        />
      )}
      {form === "account" && (
        <Account
          setForm={(page: string) => setForm(page)}
          providerName={providerName}
        />
      )}
      {form === "create" && (
        <Create setForm={(page: string) => setForm(page)} />
      )}
      {form === "code" && (
        <Code
          providerName={providerName}
          setForm={(page: string) => setForm(page)}
        />
      )}
      {form === "complete" && (
        <Complete onExplore={() => dispatch(connectWalletModalStatus(false))} />
      )}
    </>
  );
};

export default AuthModals;

