import { combineReducers } from "@reduxjs/toolkit";

import { userReducer } from "./userReducer";
import { appReducer } from "./appReducer";
import { modalsReducer } from "./modalsReducer";
import { cartReducer } from "./cartReducer";

export default combineReducers({
  user: userReducer.reducer,
  app: appReducer.reducer,
  modals: modalsReducer.reducer,
  cart: cartReducer.reducer,
});

