import { useAppSelector } from "redux/hooks";

interface Props {
  setStep: (n: number) => void;
}

const NoFunds: React.FC<Props> = () => {
  const { plaques } = useAppSelector((state) => state.cart.purchase);

  const totalPrice = plaques.reduce(
    (pre, curr) => pre + Number(curr.priceEth),
    0
  );

  return (
    <div className="mt-[24px] pb-[32px]">
      <div className="flex flex-col justify-center px-5 lg:px-8">
        <p className="text-3xl font-semibold font-saira relative text-white">
          Add Funds
        </p>
        <p className="mt-[56px] text-white">
          You don't have sufficient funds to proceed with crypto payment.
        </p>
        <p className="text-white">
          Change payment method or transfer {totalPrice} ETH + gas fees to your
          wallet.
        </p>
        <div className="flex justify-between items-center mt-[56px]"></div>
      </div>
    </div>
  );
};

export { NoFunds };
