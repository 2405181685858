import { useState } from "react";
import { Chain } from "assets/icons/Chain";
import { Facebook } from "assets/icons/Facebook";
import { TwitterWhite } from "assets/icons/Twitterwhite";
import Scrollbars from "react-custom-scrollbars-2";
import {
  cartModalStatus,
  getTPALinkModalData,
  getTPALinkModalStatus,
  setSocialShareModal,
} from "redux/reducers/modalsReducer";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { PlaqueImage } from "components/plaque-image";
import { getCurrentDomain } from "utils/location";
import { copyToClipboard } from "utils/common";
import { PlaqueItem } from "./components/plaqueItem";
import { FacebookShareButton, TwitterShareButton } from "react-share";

interface SucessProps {
  ping: string;
}

const SuccussResult: React.FC<SucessProps> = ({ ping }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [copied, setCopied] = useState(false);
  const dispatch = useAppDispatch();

  const { plaques } = useAppSelector((state) => state.cart.successPurchases);

  const plaqueUrl =
    typeof window !== "undefined"
      ? `https://stagemeta.world/tpa/${plaques[0]?.name}`
      : "";

  const facebookBody = "share_plaque_social_body";

  const twitterBody = `Check out my TPA, an innovative Web3 Addressing System.`;

  return (
    <>
      <div className="mt-[24px] p-6 lg:p-9 pt-0">
        <div className="font-saira text-xl font-semibold mb-[24px]">
          {"Purchase completed"}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className={`relative ${plaques.length > 1 ? "mt-8" : ""}`}>
            {plaques.length > 1 && (
              <div className="p-6 absolute -top-12 right-0 left-0 bottom-0">
                <PlaqueImage name="" src="" />
              </div>
            )}
            <PlaqueImage src={plaques[0].image} name={plaques[0].name} />
          </div>
          <div className="lg:px-8 flex flex-col justify-center">
            <p className="mt-[32px] text-white">
              {"Purchase has been confirmed on the Blockchain."}
            </p>

            <p className="font-semibold text-sm mt-8 text-white">
              {"Share your Teleport Plaque Address on"}:
            </p>
            <div className="flex justify-start mt-5 ">
              {plaques.length > 1 ? (
                <>
                  <button
                    onClick={() => {
                      dispatch(
                        setSocialShareModal({
                          show: true,
                          type: "link",
                        })
                      );
                    }}
                    className="mr-7 [&>svg>path]:fill-white"
                  >
                    <Chain />
                  </button>
                  <button
                    onClick={() => {
                      dispatch(
                        setSocialShareModal({
                          show: true,
                          type: "facebook",
                        })
                      );
                    }}
                    className="mr-7 [&>svg>path]:fill-white"
                  >
                    <Facebook />
                  </button>
                  <button
                    onClick={() => {
                      dispatch(
                        setSocialShareModal({
                          show: true,
                          type: "twitter",
                        })
                      );
                    }}
                    className="mr-7 [&>svg>path]:fill-white"
                  >
                    <TwitterWhite />
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => {
                      // copyToClipboard(`${twitterBody} ${plaqueUrl}`);
                      // setCopied(true);
                      // setTimeout(() => setCopied(false), 1500);
                      dispatch(getTPALinkModalData(plaques[0]));
                      dispatch(getTPALinkModalStatus(true));
                    }}
                    className="mr-7 [&>svg>path]:fill-white"
                  >
                    {copied ? "Copied" : <Chain />}
                  </button>
                  <FacebookShareButton
                    url={plaqueUrl}
                    quote={facebookBody}
                    className="mr-7 [&>svg>path]:fill-white"
                  >
                    <Facebook />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={plaqueUrl}
                    className="mr-7 [&>svg>path]:fill-white"
                    title={twitterBody}
                  >
                    <TwitterWhite />
                  </TwitterShareButton>
                </>
              )}
            </div>
            {plaques.length > 1 && (
              <div
                className="text-sm font-medium mt-5 underline cursor-pointer text-center p-4"
                onClick={() => setShowDetails(!showDetails)}
              >
                {!showDetails ? "Show Details" : "Hide Details"}
              </div>
            )}
          </div>
        </div>

        {showDetails && (
          <>
            <div className="font-semibold mt-6">Plaque List</div>
            <div className="mt-6 h-52 ">
              <Scrollbars universal>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 gap-x-16">
                  {plaques.map((p, index) => (
                    <PlaqueItem
                      key={index}
                      ethPrice={Number(p.priceEth)}
                      usdPrice={Number(p.priceUsd)}
                      imgUrl={p.image}
                      name={p.name}
                    />
                  ))}
                </div>
              </Scrollbars>
            </div>
          </>
        )}

        <style>{`
          .see-tpa-btn {
          }
        `}</style>
      </div>
    </>
  );
};

export { SuccussResult };
