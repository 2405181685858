import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  buyTpaModalStatus,
  changeCartSidebarStatus,
  connectWalletModalStatus,
  getTPALinkModalStatus,
  setSocialShareModal,
} from "redux/reducers/modalsReducer";

import Modal from "./container";
import BuyTpaModal from "./buy-tpa";
import Sidebar from "./sidebar-container";
import CartSidebar from "./cart-sidebar";
import ConnectWalletModal from "./connect-wallet";
import SocialShareModal from "./buy-tpa/socialShare";
import GetTPALinkModal from "./buy-tpa/getTPALinkModal";

const AppModals = () => {
  const dispatch = useAppDispatch();
  const { connectWallet, buyTpa, cartSidebar, socialShareModal, getTPALink } =
    useAppSelector((state) => state.modals);

  return (
    <>
      <Modal
        className={`w-[680px]`}
        maxWidth={680}
        show={connectWallet}
        onClose={() => dispatch(connectWalletModalStatus(false))}
      >
        <ConnectWalletModal />
      </Modal>

      <BuyTpaModal
        show={buyTpa}
        onClose={() => dispatch(buyTpaModalStatus(false))}
      />

      <Modal
        show={socialShareModal.show}
        maxWidth={490}
        overflow={false}
        onClose={() =>
          dispatch(
            setSocialShareModal({
              show: false,
              type: socialShareModal.type,
            })
          )
        }
      >
        <SocialShareModal />
      </Modal>

      <Modal
        maxWidth={550}
        overflow={false}
        show={getTPALink.status}
        bgRightShadow={true}
        onClose={() => dispatch(getTPALinkModalStatus(false))}
      >
        <GetTPALinkModal />
      </Modal>

      <Sidebar
        show={cartSidebar}
        title={"Cart"}
        onClose={() => dispatch(changeCartSidebarStatus(false))}
      >
        <CartSidebar />
      </Sidebar>
    </>
  );
};

export default AppModals;
