import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserTypes {
  token: string | null;
  tempToken: string | null;
  user: any;
  billingInfo: { cardId: number } | null;
  email: string | null;
}

const initialState: UserTypes = {
  token: null,
  tempToken: null,
  user: null,
  billingInfo: null,
  email: null,
};

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      state.tempToken = null;
      state.user = null;
      state.billingInfo = null;
      state.email = null;
    },
    login: (
      state,
      action: PayloadAction<{
        token?: string;
        user: any;
      }>,
    ) => {
      state.token = action.payload.token || state.token;
      state.user = action.payload.user;
    },
    setTempToken: (state, action: PayloadAction<string>) => {
      state.tempToken = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setBillingInfo: (state, action: PayloadAction<{ cardId: number }>) => {
      state.billingInfo = action.payload;
    },
    setUser: (
      state,
      action: PayloadAction<{
        user: any;
      }>,
    ) => {
      state.user = action.payload;
    },
  },
});

export const {
  logout,
  login,
  setTempToken,
  setUser,
  setBillingInfo,
  setEmail,
} = userReducer.actions;

export default userReducer.reducer;
