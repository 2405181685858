import { FC } from "react";
import { CartIcon, CloseIcon } from "utils/iconPack";

const Menu: FC<{
  close: () => void;
  open: boolean;
  cartToggle: () => void;
}> = ({ close, open, cartToggle }) => {
  return (
    <>
      <div
        className={`md:hidden w-[320px] h-screen fixed top-0 right-0 drop-shadow-lg bg-[#0F0C29] z-30 p-[25px] ${
          open ? "right-0" : "right-[-100%]"
        } transition-all duration-300 drop-shadow-xl z-50'}`}
      >
        <div className="flex justify-end py-3">
          <button onClick={close} className="text-[40px] font-mabry">
            <CloseIcon />
          </button>
        </div>
        <ul className="flex flex-col gap-[25px]">
          <div onClick={cartToggle}>
            <li className="text-white font-mabry font-bold flex items-center">
              <CartIcon />
              <span className="ml-3">Cart</span>
            </li>
          </div>
          <div className="border-t " />
          <a href="/">
            <li className="text-white font-mabry font-bold">Home</li>
          </a>
          <a href="/blog">
            <li className="text-white font-mabry font-bold">Blog</li>
          </a>
          <a href="https://mytpa.ca/blog/index.php/about-us/">
            <li className="text-white font-mabry font-bold">About</li>
          </a>
          <a href="https://mytpa.ca/blog/index.php/contact-us/">
            <li className="text-white font-mabry font-bold">Contact</li>
          </a>
        </ul>
      </div>
      {open && (
        <div
          onClick={close}
          className="fixed w-screen h-screen backdrop-blur-sm z-20 top-0 left-0"
        />
      )}
    </>
  );
};

export default Menu;
