import { useAppDispatch } from "redux/hooks";
import { setTempToken } from "redux/reducers/userReducer";
import { Request } from "api/request";
import { URLS } from "api/endpoints";
import { loginUser } from "utils/userAuth";
import useIsMobile from "utils/useIsMobile";
import { useState } from "react";
import MetamaskIcon from "assets/icons/metamaskIcon";
import { StageIcon } from "assets/icons/stageIcon";
import {
  handleSignMessage,
  getRequestedProvider,
  checkProviderAlreadyHasAccount,
  getWeb3,
  checkExtensionsIsAvailable,
} from "blockchain/auth";
import ButtonBorder from 'assets/icons/buttonBorder';
import { ProviderNames } from "constants/configs";

interface ConnectProps {
  setForm: any;
  setProviderName: (name: ProviderNames) => void;
}

const Connect: React.FC<ConnectProps> = ({
  setForm,
  setProviderName,
}: ConnectProps) => {
  const dispatch = useAppDispatch();

  const { isMobileScreen } = useIsMobile();

  const [loading, setLoading] = useState(false);

  const checkEth = async (
    providerName: ProviderNames.MetaMask | ProviderNames.CoinBase
  ) => {
    setLoading(true);
    const win = window as any;
    try {
      const checkExtentions = await checkExtensionsIsAvailable(
        providerName,
        isMobileScreen
      );
      if (win.ethereum) {
        if (checkExtentions) {
          const provider = await getRequestedProvider(providerName);
          const walletAddress = await checkProviderAlreadyHasAccount(provider);
          const web3 = await getWeb3(provider);
          let requestAddress = null as any;
          if (walletAddress) {
            requestAddress = walletAddress;
          } else {
            const [address] = await web3.eth.requestAccounts();
            requestAddress = address;
          }

          const nonce = await Request.get(URLS.auth.nonce(requestAddress));

          const sign = await handleSignMessage(
            requestAddress,
            nonce.data,
            provider
          );

          const response = await Request.post(
            URLS.auth.signature(sign.publicAddress),
            {
              signature: sign.signature,
            }
          );
          if (response) {
            if (response.data.user.emailVerify && response.data.user.username) {
              loginUser(
                response.data.token,
                response.data.user,
                providerName,
                provider
              );
              setForm("complete");
            } else {
              dispatch(setTempToken(response.data.token));
              setProviderName(providerName);
              setForm("create");
            }
          }
        }
      }
    } catch (err) {
      const x = err as any;
      if (x?.code === -32002) {
        if (win.ethereum) {
          win.ethereum.request({
            method: "wallet_requestPermissions",
            params: [{ eth_accounts: {} }],
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col flex-1 justify-center items-center  py-[80px]">
        <p className="mb-[24px] text-2xl md:text-4xl font-bold text-center mx-2 text-white">
          Login
        </p>
        <p className="mb-[24px] text-base md:text-xl font-normal mx-7 text-center md:whitespace-pre text-white">
          Continue with your wallet
        </p>
        <button
          onClick={() => {
            setProviderName(ProviderNames.StageWallet);
            setForm("email");
          }}
          className="pointer rounded-xl py-4 h-[64px] text-white bg-colorful text-lg font-semibold flex justify-center items-center w-96 max-w-full xl:w-[520px] lg:w-[300px] sm:w-[3/4]"
        >
          <StageIcon />
        </button>
        <button
          onClick={() => {
            if (loading) return;
            checkEth(ProviderNames.MetaMask);
          }}
          className={`min-h-[52px]  h-[64px] flex justify-center items-center  text-white text-lg font-semibold relative w-96 max-w-full xl:w-[520px] lg:w-[300px] mt-4 sm:w-[3/4]`}
        >
          <ButtonBorder className="absolute top-0 right-0 bottom-0 left-0" />
          <MetamaskIcon />
        </button>
        <button
          onClick={() => {
            if (loading) return;
            checkEth(ProviderNames.CoinBase);
          }}
          className={`min-h-[52px]  h-[64px] flex justify-center items-center  text-white text-lg font-semibold relative w-96 max-w-full xl:w-[520px] lg:w-[300px] mt-4 sm:w-[3/4]`}
        >
          <ButtonBorder className="absolute top-0 right-0 bottom-0 left-0" />
          <img
            alt="coinbase-wallet"
            className="coinbase-image"
            src="assets/coinBaseWallet.png"
          />
        </button>
        {loading && <p className="mt-8">Sing in metamask to continue</p>}
      </div>
      <style>
        {`
        .metamask-icon {
          width: 150px;
          height: 29.48px; 
        }
        .coinbase-image {
          width: 197px;
          height: 32px; 
        }
        .submit-btn {
          background: linear-gradient(
            107.15deg,
            #da39e0 0%,
            #6e6aff 53.65%,
            #320d6c 100%
          );
          margin-right: 8px;
          flex: 1;
        }
        `}
      </style>
    </>
  );
};

export default Connect;
