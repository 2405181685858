import { FC, useCallback, useRef } from "react";

interface Props {
  name?: string;
  src?: string;
  rounded?: boolean;
  styles?: React.CSSProperties;
}

export const PlaqueImage: FC<Props> = ({ name, src, rounded, styles }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const wrapper = useRef<HTMLDivElement | null>(null);

  const applyCanvas = useCallback(() => {
    if (!window || !name || src) return;
    const canvas = canvasRef.current;
    const canvasWrapper = wrapper.current;
    if (!canvas || !canvasWrapper) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const width = canvasWrapper.clientWidth;
    const height = canvasWrapper.clientHeight;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    canvas.width = 1000;
    canvas.height = 1000;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "white";
    ctx.textAlign = "center";
    ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 15;
    ctx.shadowBlur = 20;
    const gradient = ctx.createLinearGradient(
      0,
      0,
      canvas.width / 1.75,
      canvas.height / 3
    );
    gradient.addColorStop(0, "rgb(255, 255, 255)");
    gradient.addColorStop(1, "rgb(205, 205, 205)");
    ctx.fillStyle = gradient;
    ctx.font = `600 100pt 'Poppins'`;
    ctx.fillText(name.toLocaleUpperCase(), 500, 242);
  }, [name]);

  const handleCanvasError = (error: any) => {
    console.error("Error loading canvas:", error);
  };

  return (
    <div ref={wrapper} className="w-full h-0 pb-[100%] relative">
      <img
        alt={name || "plaque"}
        onLoad={() => {
          if (wrapper.current?.querySelector("img")?.complete) {
            applyCanvas();
          }
        }}
        src={src || "/assets/images/plaque-bg.jpeg"}
        className={`absolute object-cover ${rounded ? "rounded-3xl" : ""} `}
        style={styles}
      />

      {(name || !src) && (
        <canvas
          ref={canvasRef}
          className="relative"
          onError={handleCanvasError}
        />
      )}
    </div>
  );
};

