import * as React from 'react';
import { SVGProps } from 'react';

const SVGComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6.3335 10.333H7.66683V6.33301H6.3335V10.333ZM7.00016 4.99967C7.18905 4.99967 7.3475 4.93567 7.4755 4.80767C7.6035 4.67967 7.66727 4.52145 7.66683 4.33301C7.66683 4.14412 7.60283 3.98567 7.47483 3.85767C7.34683 3.72967 7.18861 3.6659 7.00016 3.66634C6.81127 3.66634 6.65283 3.73034 6.52483 3.85834C6.39683 3.98634 6.33305 4.14456 6.3335 4.33301C6.3335 4.5219 6.3975 4.68034 6.5255 4.80834C6.6535 4.93634 6.81172 5.00012 7.00016 4.99967ZM7.00016 13.6663C6.07794 13.6663 5.21127 13.4912 4.40016 13.141C3.58905 12.7908 2.8835 12.3159 2.2835 11.7163C1.6835 11.1163 1.20861 10.4108 0.858829 9.59967C0.509052 8.78856 0.333941 7.9219 0.333496 6.99967C0.333496 6.07745 0.508607 5.21079 0.858829 4.39967C1.20905 3.58856 1.68394 2.88301 2.2835 2.28301C2.8835 1.68301 3.58905 1.20812 4.40016 0.858341C5.21127 0.508563 6.07794 0.333452 7.00016 0.333008C7.92239 0.333008 8.78905 0.508119 9.60016 0.858341C10.4113 1.20856 11.1168 1.68345 11.7168 2.28301C12.3168 2.88301 12.7919 3.58856 13.1422 4.39967C13.4924 5.21079 13.6673 6.07745 13.6668 6.99967C13.6668 7.9219 13.4917 8.78856 13.1415 9.59967C12.7913 10.4108 12.3164 11.1163 11.7168 11.7163C11.1168 12.3163 10.4113 12.7915 9.60016 13.1417C8.78905 13.4919 7.92239 13.6668 7.00016 13.6663ZM7.00016 12.333C8.48905 12.333 9.75016 11.8163 10.7835 10.783C11.8168 9.74967 12.3335 8.48856 12.3335 6.99967C12.3335 5.51079 11.8168 4.24967 10.7835 3.21634C9.75016 2.18301 8.48905 1.66634 7.00016 1.66634C5.51127 1.66634 4.25016 2.18301 3.21683 3.21634C2.1835 4.24967 1.66683 5.51079 1.66683 6.99967C1.66683 8.48856 2.1835 9.74967 3.21683 10.783C4.25016 11.8163 5.51127 12.333 7.00016 12.333Z"
      fill="white"
    />
  </svg>
);
export default SVGComponent;
