import { useState } from "react";
import { useForm } from "react-hook-form";
import { InputInlineError } from "components/inline-error";
import { EmailRegex } from "constants/regex";
import { Request } from "api/request";
import { URLS } from "api/endpoints";
import { useAppDispatch } from "redux/hooks";
import { setEmail } from "redux/reducers/userReducer";

interface EmailFormData {
  email: string;
}

interface EmailProps {
  setForm: (value: string) => void;
}

const Email: React.FC<EmailProps> = ({ setForm }: EmailProps) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<EmailFormData>();

  const emailError = errors.email?.message || errors.email?.type;

  const onSubmit = async (data: EmailFormData) => {
    setLoading(true);

    try {
      const emailVerify = await Request.post(URLS.auth.stageWalletRegister, {
        email: data.email,
      }).catch((err: any) => {
        setError("email", {
          message: err?.data?.message,
          type: "required",
        });
      });
      if (emailVerify) {
        dispatch(setEmail(data.email));
        setForm("code");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col flex-1 justify-center items-center py-[80px]">
        <span className="text-2xl md:text-4xl font-normal text-center mt-12 mb-8 text-white">
          Login
        </span>
        <form
          className="w-full md:w-[384px] mb-7 text-black"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-ful">
            <input
              className="w-full h-[48px] p-4 border border-[#E5E5E5] rounded-md placeholder:text-[#737373]"
              placeholder={"email"}
              {...register("email", {
                required: "Please enter you email addresss",
                validate: {
                  please_enter_a_valid_field: (value) =>
                    EmailRegex.test(value?.trim().toLowerCase()),
                },
              })}
              disabled={loading}
              type="email"
            />
            <div className="mt-3 text-center">
              <InputInlineError error={emailError} />
            </div>
          </div>

          <button
            type="submit"
            disabled={loading}
            className="flex mt-8 bg-colorful h-[48px] items-center justify-center flex-row w-full md:w-[384px] rounded-2xl"
          >
            {loading ? (
              <span
                className={`w-full h-full flex items-center justify-center relative ${
                  loading ? "block" : "hidden"
                }`}
              >
                Loading...
              </span>
            ) : (
              <span className="text-white text-xl font-semibold ">
                Continue
              </span>
            )}
          </button>
        </form>
      </div>
      <style>
        {`
          .metamask-icon {
            width: 150px;
            height: 29.48px;
          }
          .confirm-btn {
            background: linear-gradient(
              107.15deg,
              #da39e0 0%,
              #6e6aff 53.65%,
              #320d6c 100%
            );
          }
        `}
      </style>
    </>
  );
};

export default Email;
