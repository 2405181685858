import { FC } from "react";

interface Props {
  message: string;
  type: "error" | "success";
  className?: string;
}

export const Alert: FC<Props> = ({ message, type, className }) => {
  if (!message) return <></>;

  if (type === "error") {
    return (
      <div className={className}>
        <div className="border-red-500 border-2 rounded bg-red-100 p-2 text-red-500 flex items-center text-xs font-medium">
          <span className="ml-1">{message}</span>
        </div>
      </div>
    );
  }
  if (type === "success") {
    return (
      <div className={className}>
        <div className="border-teal-500 border-2 rounded bg-teal-100 p-2 text-teal-500 flex items-center text-xs font-medium">
          <span className="ml-1">{message}</span>
        </div>
      </div>
    );
  }

  return <></>;
};

