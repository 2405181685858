import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useEffect, useRef, useState } from "react";
import OwnedPlaqueDropdown from "components/OwnedTPADropDown";
import { IPlaques } from "entities/plaque.entity";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { getCurrentDomain } from "utils/location";
import { copyToClipboard } from "utils/common";
import {
  getTPALinkModalData,
  getTPALinkModalStatus,
} from "redux/reducers/modalsReducer";

const SocialShareModal = () => {
  const [selected, setSelected] = useState<IPlaques | null>(null);
  const [isCopied, setIsCopied] = useState(false);
  const dispatch = useAppDispatch();

  const { plaques } = useAppSelector((state) => state.cart.successPurchases);
  const { type } = useAppSelector((state) => state.modals.socialShareModal);

  const facebookRef = useRef<HTMLButtonElement>(null);
  const twitterRef = useRef<HTMLButtonElement>(null);

  const plaqueUrl =
    typeof window !== "undefined"
      ? `https://stagemeta.world/tpa/${selected?.name}`
      : "";

  const facebookBody = `Check out my TPA, a Web3 Addressing System from Stage Meta . Get yours and Ping (${selected?.name?.toLocaleUpperCase()}) to receive DELTA Energy.`;

  const twitterBody = `Check out my TPA, an innovative Web3 Addressing System.`;

  const onSubmit = () => {
    switch (type) {
      case "link":
        dispatch(getTPALinkModalData(plaques[0]));
        dispatch(getTPALinkModalStatus(true));
        break;
      case "facebook":
        facebookRef.current?.click();
        break;
      case "twitter":
        twitterRef.current?.click();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setIsCopied(false);
  }, [selected]);

  return (
    <div className="p-9">
      <div className="-mt-14 font-semibold text-sm flex flex-row items-center justify-start">
        <span className="mr-2">Share Teleport Plaque Address</span>
      </div>
      <OwnedPlaqueDropdown
        plaques={plaques}
        selected={selected}
        setSelected={setSelected}
      />
      <button
        disabled={!selected}
        onClick={onSubmit}
        className={`${
          !selected
            ? "bg-gray-500 h-[48px] cursor-not-allowed text-gray-300"
            : isCopied
            ? "bg-green-600"
            : "bg-colorful"
        } w-full rounded-xl font-semibold h-[48px]`}
      >
        {isCopied ? "Copied!" : "Generate a Link"}
      </button>
      <FacebookShareButton
        ref={facebookRef}
        url={plaqueUrl}
        quote={facebookBody}
        className="hidden"
      >
        <></>
      </FacebookShareButton>
      <TwitterShareButton
        ref={twitterRef}
        url={plaqueUrl}
        className="hidden"
        title={twitterBody}
      >
        <></>
      </TwitterShareButton>
    </div>
  );
};

export default SocialShareModal;
