import { Request } from "api/request";
import { URLS } from "api/endpoints";
import axios from "axios";

/* eslint-disable */

async function getContracts(address: string, web3: any) {
  const latest = await Request.get(URLS.contracts.latest);

  const contract = await axios({
    url: latest.data.abiAddress, //your url
    method: "GET",
    responseType: "blob", // important
  });

  let contractObj = {
    abi: JSON.parse(await contract?.data?.text()) as any,
    address: address === "" ? latest.data.address : address,
  };

  const instance = new web3.eth.Contract(contractObj?.abi, contractObj.address);

  return instance;
}

async function getLatestAddress() {
  const latest = await Request.get(URLS.contracts.latest);
  let contractWalletAddress = "";
  if (latest) {
    contractWalletAddress = latest.data.address;
  }
  return contractWalletAddress;
}

class ContractInstance {
  getContract = async (address = "", web3: any) => {
    const instance = getContracts(address, web3);
    return instance;
  };

  getWalletAddress = async () => {
    const address = getLatestAddress();
    return address;
  };
}

export default ContractInstance;

