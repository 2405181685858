import { useState } from "react";
import { IPlaques } from "entities/plaque.entity";
import { ArrowBot, InfoIcon } from "utils/iconPack";

interface IProps {
  selected: IPlaques | null;
  setSelected: (n: IPlaques | null) => void;
  plaques: IPlaques[];
}

const OwnedPlaqueDropdown: React.FC<IProps> = ({
  selected,
  setSelected,
  plaques,
}) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <>
      <div className="my-8 relative">
        <div className="flex items-center mb-2">
          <span className="text-xs mr-3 font-normal">
            Select A Teleport Plaque Address:
          </span>
          <span className="hint cursor-pointer relative">
            <InfoIcon />
            <span className="tooltiptext w-max relative bottom-6 font-normal -right-4 p-4 rounded text-xs">
              Here you should select a TPA you want to get pinged with
              <div className="arrow-down absolute"></div>
            </span>
          </span>
        </div>
        <div
          className={`h-[48px] relative overflow-hidden p-0.5 rounded mt-0.5 bg-colorful`}
        >
          <button
            onClick={() => setShow(!show)}
            className=" flex flex-row items-center justify-between h-full w-full bg-white p-3 pr-4 rounded"
          >
            {selected && (
              <div className="flex flex-1 items-center justify-start">
                <div className="w-8 h-8 mr-3  font-medium">
                  <img src={selected?.image} alt={selected?.name} />
                </div>
                <div className="text-sm text-black font-saira">
                  {selected?.name?.toUpperCase()}
                </div>
              </div>
            )}
            {!selected && <div className="flex-1 flex" />}
            <ArrowBot />
          </button>
        </div>
        {show && (
          <div
            className={`bg-colorful w-full  ${
              plaques.length > 0 ? "h-44" : " h-14"
            }  p-0.5 rounded-md absolute mt-2`}
          >
            <div
              className={`w-full h-full bg-white rounded-md ${
                plaques.length > 0
                  ? "overflow-y-scroll "
                  : "items-center justify-center flex"
              }`}
            >
              {plaques.length === 0 ? (
                <span className="font-styling no_plaque font-normal text-sm">
                  No Teleport Plaque Address
                </span>
              ) : (
                plaques.map((p, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      setSelected(p);
                      setShow(false);
                    }}
                    className="flex items-center justify-start h-[56px] w-full bg-white p-2 rounded"
                  >
                    <div className="mr-3 font-medium">
                      <img className="w-[32px] h-[32px]" src={p?.image} alt={p?.name} />
                    </div>
                    <div className="text-sm text-black font-saira">
                      {p?.name?.toUpperCase()}
                    </div>
                  </button>
                ))
              )}
            </div>
          </div>
        )}
      </div>
      <style>{`
        .box {
          background: rgba(255, 255, 255, 0.12);
          border-image: linear-gradient(
            160deg,
            #da39e0 20%,
            #6e6aff 40%,
            #320d6c 85%
          );
          border-width: 1px;
          border-image-slice: 1;
        }
        .hint {
        }
        .arrow-down {
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          right: 15px;
          bottom: -8px;
          border-top: 8px solid white;
        }
        .hint .tooltiptext {
          visibility: hidden;
          background-color: white;
          color: black;

          /* Position the tooltip text - see examples below! */
          position: absolute;
          z-index: 1;
        }
        .hint:hover .tooltiptext {
          visibility: visible;
        }
        .font-styling {
          font-family: Inter;
        }
        .no_plaque {
          color: #737373;
        }
      `}</style>
    </>
  );
};

export default OwnedPlaqueDropdown;
