export const Refetch: React.FC = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.537 18.067C14.7224 19.6393 12.401 20.5033 10 20.5C4.477 20.5 0 16.023 0 10.5C0 4.977 4.477 0.5 10 0.5C15.523 0.5 20 4.977 20 10.5C20 12.636 19.33 14.616 18.19 16.24L15 10.5H18C17.9998 8.65621 17.3628 6.86906 16.1967 5.44089C15.0305 4.01272 13.4069 3.03119 11.6003 2.66236C9.79381 2.29352 7.91533 2.56002 6.28268 3.41677C4.65002 4.27351 3.36342 5.66791 2.64052 7.36408C1.91762 9.06025 1.80281 10.9541 2.31549 12.7251C2.82818 14.4962 3.93689 16.0358 5.45408 17.0836C6.97127 18.1313 8.80379 18.6228 10.6416 18.4749C12.4795 18.327 14.2099 17.5488 15.54 16.272L16.537 18.067Z"
        fill="white"
      />
    </svg>
  );
};
