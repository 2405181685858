import { FC } from "react";
import { Control, Controller, ControllerProps } from "react-hook-form";
import { InputInlineError } from "components/inline-error";

interface Props {
  control: Control<any, any>;
  label?: string;
  name: string;
  placeholder?: string;
  rules?: ControllerProps["rules"];
  className?: string;
  defaultValue?: string;
}

export const TextInput: FC<Props> = ({
  control,
  placeholder,
  label,
  name,
  rules,
  className,
  defaultValue,
}) => {
  return (
    <div>
      {!!label && (
        <label htmlFor={name} className="text-xs font-medium">
          {label}{" "}
          {rules?.required && (
            <span className="text-red-500 ml-0.5 text-sm">*</span>
          )}
        </label>
      )}
      <Controller
        defaultValue={defaultValue}
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <>
            <div
              className={`${className} input-wrapper relative overflow-hidden p-[1px] rounded mt-[2px] ${
                error ? "bg-red-500" : "bg-colorful"
              }`}
            >
              <input
                defaultValue={defaultValue}
                id={name}
                className={`text-lg p-4 pr-8  rounded w-full h-[50px] placeholder:text-lg ${
                  error
                    ? "text-red-500 placeholder:text-red-500 bg-red-100"
                    : "text-black bg-white"
                }`}
                {...(placeholder && { placeholder })}
                {...field}
              />
            </div>
            <InputInlineError error={error?.message} />
          </>
        )}
      />
      <style>
        {`
        .input-wrapper {
          height: 50px;
        }
        `}
      </style>
    </div>
  );
};
