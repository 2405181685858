import { getCookie, setCookies, removeCookies } from "cookies-next";
import { logout, login } from "redux/reducers/userReducer";
import { cleanUpStates } from "redux/reducers/modalsReducer";
import {
  subscribeToWeb3Events,
  unSubscribeToWeb3Events,
  getRequestedProvider,
} from "blockchain/auth";
import { store } from "redux/store";
import { Request } from "api/request";
import { URLS } from "api/endpoints";
import { META_PROVIDER_NAME, META_TOKEN } from "constants/cookie";
import { ProviderNames } from "constants/configs";
import { setPurchasePaymentMethod } from "redux/reducers/cartReducer";
import { cleanAllCarts, cleanCart } from "cart/functions";

export async function checkUser(fetchUserData?: boolean) {
  const token = getCookie(META_TOKEN) as any;
  if (token && token !== "null") {
    if (fetchUserData) {
      try {
        const res = await Request.get(URLS.auth.profile);
        if (res) {
          store.dispatch(
            login({
              token,
              user: res?.data,
            })
          );
          if (res?.data.isStageWallet) {
            store.dispatch(setPurchasePaymentMethod("debit"));
          }
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    }
    return true;
  }
  return false;
}

export async function logoutUser() {
  const providerNameCookie = getCookie(META_PROVIDER_NAME) as any;
  if (
    providerNameCookie !== null &&
    providerNameCookie !== "null" &&
    providerNameCookie !== undefined &&
    providerNameCookie
  ) {
    if (providerNameCookie !== ProviderNames.StageWallet) {
      const provider = await getRequestedProvider(providerNameCookie);
      unSubscribeToWeb3Events(provider);
    }
  }
  if (process.env.NODE_ENV === "development") {
    removeCookies(META_TOKEN);
    removeCookies(META_PROVIDER_NAME);
  }
  setCookies(META_TOKEN, null, {
    httpOnly: false,
    path: "/",
  });
  setCookies(META_PROVIDER_NAME, null, {
    httpOnly: false,
    path: "/",
  });
  localStorage.removeItem("user");
  store.dispatch(cleanUpStates());
  cleanAllCarts();
  store.dispatch(logout());
}

export function loginUser(
  token: string,
  user: any,
  providerName:
    | ProviderNames.MetaMask
    | ProviderNames.CoinBase
    | ProviderNames.StageWallet,
  provider: any
) {
  if (process.env.NODE_ENV === "development") {
    setCookies(META_TOKEN, token);
    setCookies(META_PROVIDER_NAME, providerName);
  }
  setCookies(META_TOKEN, token, {
    httpOnly: false,
    path: "/",
  });
  setCookies(META_PROVIDER_NAME, providerName, {
    httpOnly: false,
    path: "/",
  });
  store.dispatch(
    login({
      token,
      user,
    })
  );
  if (user.isStageWallet) {
    store.dispatch(setPurchasePaymentMethod("debit"));
  }
  subscribeToWeb3Events(provider, false);
  localStorage.setItem("user", JSON.stringify(user));
}
