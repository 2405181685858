import FacebookIcon from "assets/icons/FacebookIcon";
import InstagramIcon from "assets/icons/InstagramIcon";
import TwitterIcon from "assets/icons/TwitterIcon";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="px-[20px] py-[40px] md:py-[150px] root-container">
      <div className="flex justify-between flex-col md:flex-row">
        <div>
          <span className="font-mabry font-bold text-[30px] text-white">
            My TPA
          </span>
          <p className="font-inter font-normal text-[14px] text-white max-w-[655px] my-[24px]">
            Elevate your Web3 presence and embrace the future by acquiring your
            unique TPA. TPA functions seamlessly across the entire Web3,
            allowing its utilization for Decentralized Domains, Wallet
            addresses, and Metaverse addresses.
          </p>
          <div className="flex items-center gap-[24px]">
            <a
              href="https://instagram.com/mytpa_meta/"
              className="w-[48px] h-[48px] cursor-pointer rounded-full flex justify-center items-center"
              style={{ background: "rgba(255, 255, 255, 0.08)" }}
            >
              <InstagramIcon />
            </a>
            {/* <a
              href=""
              className="w-[48px] h-[48px] cursor-pointer rounded-full flex justify-center items-center"
              style={{ background: "rgba(255, 255, 255, 0.08)" }}
            >
              <TwitterIcon />
            </a>
            <a
              href=""
              className="w-[48px] h-[48px] cursor-pointer rounded-full flex justify-center items-center"
              style={{ background: "rgba(255, 255, 255, 0.08)" }}
            >
              <FacebookIcon />
            </a> */}
          </div>
        </div>
        <div className="flex flex-col mt-[50px] pr-28 md:mt-0">
          <span className="font-mabry font-bold text-[24px] text-white">
            Main Menu
          </span>
          <a
            className="font-mabry font-bold text-[16px] mt-[32px] text-white cursor-pointer"
            href="/blog"
          >
            Blog
          </a>
          <a
            href="https://mytpa.ca/blog/index.php/about-us/"
            className="font-mabry font-bold text-[16px] mt-[32px] text-white cursor-pointer"
          >
            About us
          </a>
          <a
            href="https://mytpa.ca/blog/index.php/contact-us/"
            className="font-mabry font-bold text-[16px] mt-[32px] text-white cursor-pointer"
          >
            Contact us
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
