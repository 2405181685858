import * as React from 'react';
import { SVGProps } from 'react';

const SVGComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M2.24078 15.1998L0.800781 13.7598L6.56078 7.9998L0.800781 2.2398L2.24078 0.799805L8.00078 6.5598L13.7608 0.799805L15.2008 2.2398L9.44078 7.9998L15.2008 13.7598L13.7608 15.1998L8.00078 9.4398L2.24078 15.1998Z"
      fill="#D5D5D5"
    />
  </svg>
);
export default SVGComponent;
