import ReactDOM from "react-dom/client";
import "../src/styles/css/main.css";
import "../src/styles/css/bootstrap.min.css";
import "../src/styles/css/owl.carousel.min.css";
import "../src/styles/css/slicknav.css";
import "../src/styles/css/flaticon.css";
import "../src/styles/css/progressbar_barfiller.css";
import "../src/styles/css/gijgo.css";
import "../src/styles/css/animate.min.css";
import "../src/styles/css/animated-headline.css";
import "../src/styles/css/magnific-popup.css";
import "../src/styles/css/fontawesome-all.min.css";
import "../src/styles/css/themify-icons.css";
import "../src/styles/css/slick.css";
import "../src/styles/css/nice-select.css";
import "../src/styles/css/style.css";
import "../src/styles/css/responsive.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "../src/redux/store";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

